import axios from 'axios'
import { API_URL, API_METHODS, TOKEN } from '../utils/globalVars'


// GET METHODS
export const getRatesCall = async () => {
    return axios({
        url: `${API_URL}/config`,
        method: API_METHODS['get'],
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const getClientsCall = async () => {
    return axios({
        url: `${API_URL}/client`,
        method: API_METHODS['get'],
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const getInvoicesCall = async (id) => {
    return axios({
        url: `${API_URL}/invoice/many?id=${id}`,
        method: API_METHODS['get'],
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const getSingleClientCall = async (id) => {
    return axios({
        url: `${API_URL}/client?id=${id}`,
        method: API_METHODS['get'],
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const getUsernamesCall = async (username, clientId) => {
    return axios({
        url: `${API_URL}/user/usernames?username=${username}&clientId=${clientId}`,
        method: API_METHODS['get'],
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const downloadInvoiceCall = async (id) => {
    return axios({
        url: `${API_URL}/invoice/print?id=${id}`,
        method: API_METHODS['get'],
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`,
            'Accept': 'application/pdf',
        },
        responseType: 'blob'
    })
}
export const notifyChangeCall = async (id) => {
    return axios({
        url: `${API_URL}/client/change?id=${id}`,
        method: API_METHODS['get'],
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`,
        }
    })
}
export const forgotPasswordCall = async (username, email) => {
    return axios({
        url: `${API_URL}/user/reset?username=${username}&email=${email}`,
        method: API_METHODS['get'],
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const notifyFilesCall = async (id) => {
    return axios({
        url: `${API_URL}/client/files?id=${id}`,
        method: API_METHODS['get'],
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}


// POST METHODS
export const loginCall = async (body) => {
    return axios({
        url: `${API_URL}/user/login`,
        method: API_METHODS['post'],
        data: body,
        headers: { 'Content-Type': 'application/json' }
    }
    )
}
export const sendInvoiceCall = async (invoiceId) => {
    return axios({
        url: `${API_URL}/invoice/send`,
        method: API_METHODS['post'],
        data: { id: invoiceId },
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)},`
        }
    })
}
export const saveClientCall = async (body) => {
    return axios({
        url: `${API_URL}/client`,
        method: API_METHODS['post'],
        data: body,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const saveInvoiceCall = async (body) => {
    return axios({
        url: `${API_URL}/invoice/new`,
        method: API_METHODS['post'],
        data: body,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const createUserCall = async (body) => {
    return axios({
        url: `${API_URL}/user/new`,
        method: API_METHODS['post'],
        data: body,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const changeClientCall = async (body) => {
    return axios({
        url: `${API_URL}/client/update`,
        method: API_METHODS['post'],
        data: body,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const contactRequestCall = async (body) => {
    return axios({
        url: `${API_URL}/client/aclaration`,
        method: API_METHODS['post'],
        data: body,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const reportPaymentCall = async (body) => {
    return axios({
        url: `${API_URL}/invoice/report`,
        method: API_METHODS['post'],
        data: body,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const restorePasswordCall = async (body) => {
    return axios({
        url: `${API_URL}/user/restore`,
        method: API_METHODS['post'],
        data: body,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

// PUT METHODS
export const saveRatesCall = async (body) => {
    return axios({
        url: `${API_URL}/config`,
        method: API_METHODS['put'],
        data: body,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const updateClientCall = async (id, body) => {
    return axios({
        url: `${API_URL}/client?id=${id}`,
        method: API_METHODS['put'],
        data: body,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const updateInvoiceCall = async (body) => {
    return axios({
        url: `${API_URL}/invoice/update`,
        method: API_METHODS['put'],
        data: body,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const updatePasswordCall = async (body) => {
    return axios({
        url: `${API_URL}/user/update`,
        method: API_METHODS['put'],
        data: body,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}


// DELETE METHODS
export const deleteClientCall = async (id) => {
    return axios({
        url: `${API_URL}/client?id=${id}`,
        method: API_METHODS['del'],
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
export const deleteInvoiceCall = async (id) => {
    return axios({
        url: `${API_URL}/invoice/delete?id=${id}`,
        method: API_METHODS['del'],
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(TOKEN)}`
        }
    })
}
