import { Select } from '@chakra-ui/react'

const ClientTypeSelect = ({ value, onChange, isPlural }) => {
    return (
        <Select minW='fit-content' w='100%' name='type' title='type' value={value} onChange={onChange}>
            {isPlural && <option value=''>Todos</option>}
            {!isPlural && <option value=''>Selecciona una opción...</option>}
            <option value='Particular'>{isPlural ? 'Particulares' : 'Particular'}</option>
            <option value='Empresa'   >{isPlural ? 'Empresas'     : 'Empresa'}</option>
        </Select>
    )
}

export default ClientTypeSelect