import { Box, Button, ButtonGroup, Center, Flex, FormControl, FormLabel, Input, Text, VStack } from "@chakra-ui/react"
import BoxTitle from "../components/BoxTitle"
import { useSignals } from "@preact/signals-react/runtime"
import { useSignal } from '@preact/signals-react'
import { useAuth } from "../contexts/AuthContext"
import { WarningTwoIcon } from "@chakra-ui/icons"
import PasswordChecker, { criteria } from "../components/PasswordChecker"
import { usePassword } from "../hooks/usePassword"
import { useLocation, useNavigate } from "react-router-dom"
import { CHANGE_PASS_ROUTE, CLIENT_SITE_ROUTE, LOGIN_ROUTE } from "../utils/globalVars"

const ChangePassPage = () => {
    useSignals()
    const navigate = useNavigate()
    const location = useLocation()
    const { isLoading, changePassword, restorePassword } = usePassword()
    const { authLogout, isDefPass } = useAuth()

    const password = useSignal('')
    const confirm  = useSignal('')
    const isMatch  = useSignal(true)

    const urlParams = new URLSearchParams(window.location.search)

    const isChange = location.pathname.includes(CHANGE_PASS_ROUTE)
    
    function handleBack() {
        isDefPass.value ? authLogout() : navigate(CLIENT_SITE_ROUTE)
    }

    function handleChange(e) {
        const { name, value } = e.target

        if (name === 'password') password.value = value
        else confirm.value = value

        isMatch.value = password.value === confirm.value
    }

    async function handleSubmit(e) {
        e.preventDefault();

        if (isChange) {
            const success = await changePassword(password.value, isMatch.value, criteria);
            if (success) {
                isDefPass.value = false;
                navigate(CLIENT_SITE_ROUTE);
            }
        } else {
            const token = urlParams.get('token');
            const success = await restorePassword(password.value, isMatch.value, criteria, token);
            if (success) {
                navigate(LOGIN_ROUTE);
            }
        }
    }

    return (
        <Center>
            <Box className='box' w='xs'>
                <BoxTitle text={isChange ? 'Cambiar Contraseña' : 'Restaurar Contraseña'} />
                <PasswordChecker password={password} />
                <VStack mt={4} w='100%'>
                    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                        <FormControl mb={4}>
                            <FormLabel>Nueva Contraseña:</FormLabel>
                            <Input
                                placeholder="Contraseña..."
                                name='password'
                                type="password"
                                value={password.value}
                                onChange={handleChange}
                                autoComplete="on"
                                required
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Confirmar:</FormLabel>
                            <Input
                                placeholder="Confirmar..."
                                name="confirm"
                                type="password"
                                value={confirm.value}
                                onChange={handleChange}
                                autoComplete="on"
                                required
                            />
                        </FormControl>
                        {!isMatch.value && (
                            <Flex w='100%' justifyContent='center' mb={4}>
                                <Text display='flex' alignItems='center' color='red.500'>
                                    <WarningTwoIcon />&nbsp; Las contraseñas no coinciden.
                                </Text>
                            </Flex>
                        )}
                        <ButtonGroup w='100%' justifyContent='space-between'>
                            {isChange && (
                                <Button isLoading={isLoading.value} minW='45%' colorScheme="yellow" onClick={handleBack}>
                                    {isDefPass.value ? 'Salir' : 'Cancelar'}
                                </Button>
                            )}
                            <Button isLoading={isLoading.value} minW={isChange ? '45%' : '100%'} colorScheme="blue" variant='outline' type='submit'>
                                {isChange ? 'Cambiar' : 'Restaurar'}
                            </Button>
                        </ButtonGroup>
                    </form>
                </VStack>
            </Box>
        </Center>
    )
}

export default ChangePassPage

/* const password = signal('')
const confirm  = signal('')
const isMatch  = signal(true)

const ChangePassPage = () => {
    useSignals()
    const navigate = useNavigate()
    const {authLogout, isDefPass } = useAuth()
    const { isLoading, changePassword } = usePassword()

    function handleChange(e) {
        const { name, value } = e.target

        if (name === 'password') password.value = value
        else confirm.value = value

        isMatch.value = password.value === confirm.value
    }

    async function handleSubmit(e) {
        e.preventDefault()
        const success = await changePassword(password.value, isMatch.value, criteria)
        if (success) {
            isDefPass.value = false
            navigate(CLIENT_SITE_ROUTE)
        }
    }
    
    return(
        <Center>
            <Box className="box" w='xs'>
                <BoxTitle text='Cambiar Contraseña' />
                <PasswordChecker password={password} />
                <VStack mt={4} w='100%'>
                    <form style={{width: '100%'}} onSubmit={handleSubmit}>
                        <FormControl mb={4}>
                            <FormLabel>Nueva Contraseña:</FormLabel>
                            <Input
                                placeholder="Contraseña..."
                                name='password'
                                type="password"
                                value={password.value}
                                onChange={handleChange}
                                autoComplete="on"
                                required
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Confirmar:</FormLabel>
                            <Input
                                placeholder="Confirmar..."
                                name="confirm"
                                type="password"
                                value={confirm.value}
                                onChange={handleChange}
                                autoComplete="on"
                                required
                            />
                        </FormControl>
                        {!isMatch.value && (
                            <Flex w='100%' justifyContent='center' mb={4}>
                                <Text display='flex' alignItems='center' color='red.500'><WarningTwoIcon />&nbsp; Las contraseñas no coinciden.</Text>
                            </Flex>
                        )}
                        <ButtonGroup w='100%' justifyContent='space-between'>
                            <Button isLoading={isLoading.value} minW='45%' colorScheme="yellow" onClick={authLogout}>Salir</Button>
                            <Button isLoading={isLoading.value} minW='45%' colorScheme="blue" variant='outline' type='submit'>Cambiar</Button>
                        </ButtonGroup>
                    </form>
                </VStack>
            </Box>
        </Center>
    )
}

export default ChangePassPage */