import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button } from '@chakra-ui/react'
import { useRef } from 'react'


const Alert = ({ isOpen, onClose, title, body }) => {
    const cancelRef = useRef()

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay backdropFilter='blur(10px)'>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>{title}</AlertDialogHeader>
                    <AlertDialogBody>{body}</AlertDialogBody>
                    <AlertDialogFooter gap={4}>
                        <Button colorScheme='yellow' ref={cancelRef} onClick={() => onClose(false)}>Cancelar</Button>
                        <Button colorScheme='blue' variant='outline' onClick={() => onClose(true)}>Confirmar</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>

        </AlertDialog>
    )
}

export default Alert