import { useEffect, useRef } from 'react'
import {
    Box,
    HStack,
    FormLabel,
    Button,
    Flex,
    Spinner,
    VStack,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react'
import BoxTitle from '../components/BoxTitle'
import { useSignal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import { formatMoney } from '../utils/globalFunctions'
import { FaFilterCircleXmark } from 'react-icons/fa6'
import useClients from '../hooks/useClients'
import useInvoices from '../hooks/useInvoices'
import InvoicesTable from '../components/InvoicesTable'
import ClientSearchDropdown from '../components/ClientSearchDropdown'

const HistoryPage = () => {
    useSignals()

    const inputVal = useSignal('')
    const total    = useSignal(0)
    const debt     = useSignal(0)
    const cleanRef = useRef()

    const isWide = useBreakpointValue({base: false, sm: true})
    const { isLoading, client } = useClients()
    const {
        isLoading: isLoadingInvoices,
        invoiceList,
        getInvoices,
    } = useInvoices()

    async function handleSelection(cl) {
        const success = await getInvoices(cl.id)

        if (success) {
            client.value = { ...cl }
            updateTotals()
        } else {
            client.value = { id: null, name: '' }
            invoiceList.value = []
            total.value = 0
            debt.value = 0
        }
    }

    function updateTotals() {
        let sumTotal = 0
        let sumDebt  = 0

        for (const item of invoiceList.value) {
            sumTotal += item.total
            sumDebt  += item.debt
        }
        total.value = sumTotal
        debt.value  = sumDebt
    }
    

    function handleClear() {
        client.value = { id: null, name: '' }
        total.value = 0
        debt.value = 0
        inputVal.value = ''
        invoiceList.value = []
    }

    useEffect(() => {
        cleanRef.current?.click()
    }, [])

    return (
        <Box className='box' maxW='8xl' minH='250px'>
            <BoxTitle text='Historial' />
            <Box justify='space-between' wrap='wrap' mb={4}>
                {isLoading.value ? (
                    <Flex>
                        <Spinner size='xl' />
                    </Flex>
                ) : (
                    <>
                        <HStack mb={8}>
                            <FormLabel mb={0} fontWeight='bold'>
                                Cliente:
                            </FormLabel>
                            <ClientSearchDropdown inputValue={inputVal} onSelect={handleSelection} />
                            <Button
                                ref={cleanRef}
                                colorScheme='yellow'
                                variant='outline'
                                w='fit-content'
                                minW={{ base: 'fit-content', sm: '100px' }}
                                onClick={handleClear}
                                isDisabled={isLoading.value || isLoadingInvoices.value}
                            >
                                {(isWide && 'Limpiar') || (<FaFilterCircleXmark size={20} />)}
                            </Button>
                        </HStack>
                        <VStack alignItems='flex-start'>
                            <HStack>
                                <FormLabel className='wide-lbl' m={0} fontWeight='bold' textAlign='right'>Nombre:</FormLabel>
                                <Text>{client.value && client.value.name}</Text>
                            </HStack>
                            <HStack>
                                <FormLabel className='wide-lbl' m={0} fontWeight='bold' textAlign='right'>Total facturado:</FormLabel>
                                <Text>{formatMoney(total.value)}</Text>
                            </HStack>
                            <HStack>
                                <FormLabel className='wide-lbl' m={0} fontWeight='bold' textAlign='right'>Adeudo total:</FormLabel>
                                <Text>{formatMoney(debt.value)}</Text>
                            </HStack>
                        </VStack>
                    </>
                )}
            </Box>
            {isLoadingInvoices.value ? (
                <Flex>
                    <Spinner size='xl' />
                </Flex>
            ) : (
                <InvoicesTable update={updateTotals} isAdmin />
            )}
        </Box>
    )
}

export default HistoryPage
