import { useSignal } from '@preact/signals-react'
import { useCallback } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { loginCall } from '../services/ApiCalls'
import { showToast } from '../utils/globalFunctions'
import { useToast } from '@chakra-ui/react'
import { ERROR, TITLE_ERROR } from '../utils/globalVars'

export const useLogin = () => {
    const toast         = useToast()
    const { authLogin } = useAuth()
    const isLoading     = useSignal(false)

    const login = useCallback(async ({ username, password }) => {
        isLoading.value = true
        try {
            const response = await loginCall({ username: username, password: password })

            if (response.status === 200) {
                authLogin(response.data)
                return true
            }
            showToast(toast, TITLE_ERROR, response.data, ERROR)
            return false
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response.data, ERROR)
            return false
        } finally {
            isLoading.value = false
        }
    }, [isLoading, authLogin, toast])

    return { login, isLoading }
}
