import { useEffect, useRef } from 'react'
import {
    Box,
    Button,
    Flex,
    FormLabel,
    Input,
    HStack,
    useDisclosure,
    Text,
    VStack,
    Divider,
    Table,
    Thead,
    Tr,
    Th,
    Tfoot,
    useBreakpointValue
} from '@chakra-ui/react'
import ClientSearchDropdown from '../components/ClientSearchDropdown'
import { signal, useSignal } from '@preact/signals-react'
import { useComputed, useSignals } from '@preact/signals-react/runtime'
import { AddIcon, EmailIcon } from '@chakra-ui/icons'
import InvoiceStatusSelect from '../components/InvoiceStatusSelect'
import { formatMoney } from '../utils/globalFunctions'
import { FaDollarSign } from 'react-icons/fa6'
import InvoiceRows from '../components/InvoiceRows'
import { useRates } from '../hooks/useRates'
import { FaSave } from 'react-icons/fa'
import PaymentModal from '../components/modals/PaymentModal'
import useInvoices from '../hooks/useInvoices'
import DownloadPdf from '../components/DownloadPdf'
import Alert from '../components/modals/Alert'
import useClients from '../hooks/useClients'
import { CLIENTS_ROUTE } from '../utils/globalVars'
import { useNavigate } from 'react-router-dom'

const emptyItems = [{ acn: '', description: '', time: '', amount: '' }]
const isDirty = signal(false)

const InvoicePage = () => {
    useSignals()
    const navigate = useNavigate()
    const dateRef = useRef(null)
    const isWide  = useBreakpointValue({base: false, md: true})
    const isBtnWide = useBreakpointValue({base: false, sm: true})

    // Hooks
    const { minute } = useRates()
    const { client } = useClients()
    const { saveInvoice, setInvoiceBody, updateInvoice, sendInvoice, isLoading, isEditInvoice, clientInvoice } = useInvoices()

    // Disclosures
    const { isOpen: isPaymentOpen, onOpen: onPaymentOpen, onClose: onPaymentClose } = useDisclosure()
    const { isOpen: isAlertOpen,   onOpen: onAlertOpen,   onClose: onAlertClose   } = useDisclosure()
    
    
    // Helper signals
    const clientInputValue = useSignal('')
    const minuteRate = useSignal(Number(minute.value))
    const time = useSignal(0)
    const paid = useSignal(0)
    const alertData = useSignal({title: '', body: ''})
    const paymentObject = useSignal(null)
    const isInvoiceSaved = useSignal(false)
    const invoiceId = useSignal(null)
    const isEditRef = useRef(isEditInvoice.value)

    // Invoice Properties
    const status = useSignal('Pendiente')
    const items  = useSignal(emptyItems)
    const date   = useSignal(new Date().toISOString().split('T')[0])
    const total  = useComputed(() => Number((time.value * (minuteRate.value)).toFixed(2)))
    const debt   = useComputed(() => Number((total - paid.value).toFixed(2)))
    

    function handleNewInvoice() {
        if (isDirty.value) {
            alertData.value = {title: 'Descartar cambios', body: '¿Deseas descartar los cambios de la factura actual y crear una nueva?'}
            onAlertOpen()
        } else {
            handleReset()
        }
    }
    function handleClientSelect(cl) {
        client.value  = cl
        isDirty.value = true
    }
    function handleDateChange(e) {
        date.value    = e.target.value
        isDirty.value = true
    }
    function handleStatusChange(e) {
        status.value  = e.target.value
        isDirty.value = true
    }

    function handleNewClient() {
        navigate(CLIENTS_ROUTE)
    }


    async function handleAlertClose(isConfirmed) {
        onAlertClose()

        if (!isConfirmed) return

        if (alertData.value.title === 'Descartar cambios') {
            handleReset()
        }
    }

    function handlePaymentClose() {
        const {pay, added } = paymentObject.value || {}

        if (pay > 0) {
            paid.value = added ? paid.value + pay : pay
            isDirty.value = true
        } else if (pay === -1) {
            paid.value = total.value
            status.value = 'Pagada'
            isDirty.value = true
        }
        paymentObject.value = null
        onPaymentClose()
    }
    

    function handleInputChange(i, field, value) {
        const currentItems = [...items.value]
        const item = currentItems[i]
        item[field] = value

        if (field === 'time') {
            if (minuteRate.value === 0) {
                minuteRate.value = Number(minute.value)
            }
            const val = parseFloat(value)

            item['amount'] = (val > 0 ? val * minuteRate.value : 0)
        }
        items.value = currentItems
        updateTotalTime(currentItems)
        isDirty.value = true
    }
    
    function updateTotalTime(rows) {
        time.value = rows.reduce((sum, row) => sum + (parseInt(row.time || 0)), 0)
    }

    function handleAddRow() {
        items.value = [...items.value, { acn: '', description: '', time: '', amount: '' }]
        isDirty.value = true
    }

    function handleDeleteRow(idx) {
        const newRows = items.value.filter((_, i) => i !== idx)
        items.value   = newRows
        isDirty.value = true
        updateTotalTime(newRows)
    }

    function setBody() {
        return setInvoiceBody(
                isInvoiceSaved.value,
                invoiceId.value,
                client.value,
                items.value,
                {
                    status: status.value,
                    payment: paid.value,
                    debt: status.value !== 'Pagada' ? Number(debt.value) : 0,
                    total: Number(total.value),
                    date: date.value,
                }
            )
    }

    async function handleSave() {
        const body = setBody()
        if (!body) return

        let success

        if (!isInvoiceSaved.value) {
            success = await saveInvoice(body)
            if (success) {
                invoiceId.value = success[0]
            }
        } else {
            success = await updateInvoice(body)
        }

        if (success) {
            processSuccess(success[1] ?? success)

        }
    }

    function processSuccess(rowsIdList) {
        const rowsIds = rowsIdList.slice(1, -1).split(',').map(item => Number(item.replace(/'/g, '')))

        items.value.forEach((item, index) => {
            item.acn = rowsIds[index]
        });

        isInvoiceSaved.value = true
        isDirty.value = false
    }

    async function handleSend() {
        await handleSave()
        if (invoiceId.value > 0) {    
            await sendInvoice(invoiceId.value)
        }
    }

    function handleReset() {
        isDirty.value          = false
        status.value           = 'Pendiente'
        date.value             = new Date().toISOString().split('T')[0]
        items.value            = [{ acn: '', description: '', time: '', amount: '' }]
        time.value             = 0
        paid.value             = 0
        client.value           = { id: null, name: '' }
        alertData.value        = {title: '', body: ''}
        invoiceId.value        = null
        minuteRate.value       = Number(minute.value)
        paymentObject.value    = null
        isEditInvoice.value    = false
        isEditRef.current      = false
        isInvoiceSaved.value   = false
        clientInputValue.value = ''
    }

    function setupEditInvoice() {
        
        isDirty.value   = true
        status.value    = clientInvoice.value.invoice.status
        date.value      = clientInvoice.value.invoice.date.split(' ')[0]
        paid.value      = clientInvoice.value.invoice.total - clientInvoice.value.invoice.debt
        invoiceId.value = clientInvoice.value.invoice.id
        isInvoiceSaved.value = true
        
        items.value   = null
        minuteRate.value = null

        items.value = []
        clientInvoice.value.invoice.items.forEach(item => {
            items.value.push({ acn: item.item_id, description: item.description, time: item.time_spent, amount: item.subtotal })
            time.value += item.time_spent
        });
        minuteRate.value = clientInvoice.value.invoice.total / time.value
        isEditInvoice.value = false
    }

    const setupEditRef = useRef(setupEditInvoice)

    useEffect(() => {
        if (isEditRef.current) {
            setupEditRef.current()
            isDirty.value = true

            isEditRef.current = false
        } else {
            client.value = { id: null, name: '' }
        }
    },[client])

    return (
        <Box className='box' maxW='8xl' minW='xs' minH='250px'>
            <Alert isOpen={isAlertOpen} onClose={handleAlertClose} title={alertData.value.title} body={alertData.value.body} />
            <PaymentModal isOpen={isPaymentOpen} onClose={handlePaymentClose} isConfirmed={paymentObject} />
            <Flex justifyContent='flex-start' w='100%' mb={4} borderBottom='2px solid' borderColor='blue.300' pb={2}>
                <Button colorScheme='blue' variant='outline' isDisabled={isLoading.value} onClick={handleNewInvoice}>Nueva Factura</Button>
            </Flex>
            <>
                <HStack mb={4} justifyContent='space-between' alignItems='flex-start' w='100%' flexDir={{ base: 'column', md: 'row' }}>
                    <Flex alignItems='center' gap={4} w={{ base: '100%', md: '50%', xl: '35%' }}>
                        <FormLabel minW='fit-content' mb={0} fontWeight='bold'>Cliente:</FormLabel>
                        <ClientSearchDropdown inputValue={clientInputValue} onSelect={handleClientSelect} />
                        <Button colorScheme='blue' variant='outline' w='fit-content' minW={{ base: 0, md: '100px' }} onClick={handleNewClient} isDisabled={isLoading.value}>
                            {(isWide && 'Nuevo') || <AddIcon />}
                        </Button>
                    </Flex>
                    <Flex alignItems='center' gap={4}>
                        <FormLabel minW='60px' mb={0} fontWeight='bold' textAlign='right'>Fecha:</FormLabel>
                        <Input type='date' value={date} onChange={handleDateChange} isDisabled={isLoading.value} />
                    </Flex>
                </HStack>
                <HStack justifyContent='space-between' mb={{ base: 4, md: 0 }} alignItems='flex-start' w='100%' flexDir={{ base: 'column-reverse', md: 'row' }}>
                    <Flex gap={4} alignItems='center' mt={{ base: 4, md: 0 }}>
                        <FormLabel minW='117px' mb={0} fontWeight='bold' textAlign='right'>Nombre:</FormLabel>
                        <Text>{client.value?.name || ''}</Text>
                    </Flex>
                    {!isWide && <Divider h='1px' bg='blue.500' mt={4} />}
                    <Flex gap={4} alignItems='center'>
                        <FormLabel minW='fit-content' mb={0} fontWeight='bold' textAlign='right'>Estatus:</FormLabel>
                        <InvoiceStatusSelect value={status} onChange={handleStatusChange} />
                    </Flex>
                </HStack>
                {status.value !== 'Pagada' && <VStack w='100%' alignItems='flex-start'>
                    <Flex gap={4} alignItems='center'>
                        <FormLabel minW='117px' mb={0} fontWeight='bold' textAlign='right'>Adeudo:</FormLabel>
                        <Text>{formatMoney(debt.value)}</Text>
                    </Flex>
                    <Flex gap={4} alignItems='center'>
                        <FormLabel minW='117px' mb={0} fontWeight='bold' textAlign='right'>Monto pagado:</FormLabel>
                        <Text>{formatMoney(paid.value)}</Text>
                        <Button colorScheme='blue' w='fit-content' minW={{ base: 0, md: '100px' }} onClick={onPaymentOpen}>
                            {(isWide && 'Agregar Pago') || <><AddIcon />&nbsp;<FaDollarSign /></>}
                        </Button>
                    </Flex>
                </VStack>}
            </>
            <Table variant='striped' colorScheme='blue' size='sm' mt={7} className='table'>
                <Thead>
                    <Tr>
                        <Th scope='row' className='hidden' bg='blue.900'></Th>
                        <Th scope='col' w='5%' minW='25px'>ACN</Th>
                        <Th scope='col'>Descripción</Th>
                        <Th scope='col' w='15%'>Tiempo (minutos)</Th>
                        <Th scope='col' isNumeric w='15%'>Importe</Th>
                    </Tr>
                </Thead>
                <InvoiceRows rows={items} disableInput={false} onChange={handleInputChange} onDeleteRow={handleDeleteRow} />
                <Tfoot>
                    <Tr>
                        <Th scope='row' className='hidden' bg='blue.900'></Th>
                        <Th scope='col' w='5%' minW='25px'></Th>
                        <Th scope='col'></Th>
                        <Th scope='col' w='15%' ml={4}>Total: {parseInt(time.value)}</Th>
                        <Th scope='col' isNumeric w='15%'></Th>
                    </Tr>
                </Tfoot>
            </Table>
            <HStack w='100%' mb={4}>
                <Button size='sm' onClick={handleAddRow} colorScheme='blue' ><AddIcon /></Button>
            </HStack>
            <HStack justify='space-between' w='100%' flexDir={{ base: 'column-reverse', lg: 'row' }}>
                <Flex gap={4}>
                    <Button isLoading={isLoading.value} minW={{ base: 0, md: '100px' }} colorScheme='blue' variant={'outline'} onClick={handleSave}>{(isBtnWide && 'Guardar') || <FaSave />}</Button>
                    <DownloadPdf minW={{ base: 0, md: '100px' }} date={dateRef.current !== null ? dateRef.current.value : new Date().toISOString().split('T')[0]} clientName={client.value !== null ? client.value.name : ''} invoiceId={invoiceId.value !== null ? invoiceId.value : ''} />
                    <Button onClick={handleSend} isLoading={isLoading.value} minW={{ base: 0, sm: '100px' }} color='white' colorScheme='green'>{(isBtnWide && 'Enviar') || <EmailIcon />}</Button>
                </Flex>
                {!isWide && <Divider h='1px' bg='blue.500' m={2}/>}
                <HStack >
                    <FormLabel m={0} mr={4} style={{ textWrap: 'nowrap' }} fontSize={20} fontWeight='bold'>Total:</FormLabel>
                    <Box style={{ textWrap: 'nowrap' }} textAlign='right' fontSize={20} mr={4}>
                        {formatMoney(total.value)}
                    </Box>
                </HStack>
            </HStack>
        </Box>
    )
}

export default InvoicePage
