import { Navigate, Route, Routes } from 'react-router-dom'
import { useAuth } from './contexts/AuthContext'
import { useSignals } from '@preact/signals-react/runtime'
import LoginPage from './pages/LoginPage'
import InvoicePage from './pages/InvoicePage'
import ClientsPage from './pages/ClientsPage'
import ConfigPage from './pages/ConfigPage'
import ClientSitePage from './pages/ClientSitePage'
import HistoryPage from './pages/HistoryPage'
import ChangePassPage from './pages/ChangePassPage'
import GuidePage from './pages/GuidePage'
import {
    CHANGE_PASS_ROUTE,
    CLIENT_SITE_ROUTE,
    CLIENTS_ROUTE,
    CONFIG_ROUTE,
    GUIDE_ROUTE,
    HISTORY_ROUTE,
    INVOICE_ROUTE,
    LOGIN_ROUTE,
    RESET_PASS_ROUTE,
    TOKEN
} from './utils/globalVars'


const SiteRoutes = () => {
    useSignals()

    const { isAuth }   = useAuth()
    return (
        <Routes>
            <Route path='/'                 element={<HomeRoute />} />
            <Route path={GUIDE_ROUTE}       element={<GuidePage />} />
            <Route path={LOGIN_ROUTE}       element={isAuth.value ? <Navigate to='/' /> : <LoginPage />} />
            <Route path={INVOICE_ROUTE}     element={<PrivateRoute component={InvoicePage} />} />
            <Route path={CLIENTS_ROUTE}     element={<PrivateRoute component={ClientsPage} />} />
            <Route path={HISTORY_ROUTE}     element={<PrivateRoute component={HistoryPage} />} />
            <Route path={CONFIG_ROUTE}      element={<PrivateRoute component={ConfigPage} />} />
            <Route path={CLIENT_SITE_ROUTE} element={<PrivateRoute component={ClientSitePage} />} />
            <Route path={CHANGE_PASS_ROUTE} element={<PrivateRoute component={ChangePassPage} />} />
            <Route path={RESET_PASS_ROUTE}  element={isAuth.value ? <Navigate to='/' /> : <ChangePassPage />} />
        </Routes>
    )
}

const HomeRoute = () => {
    const { isAuth, userId } = useAuth()
    return isAuth.value 
            ? (userId.value === 1 
                ? <Navigate to={INVOICE_ROUTE} /> 
                : <Navigate to={CLIENT_SITE_ROUTE} />)
            : <Navigate to={LOGIN_ROUTE} />
}

const PrivateRoute = ({ component: Component }) => {
    const { isAuth, isDefPass } = useAuth()
    const token = sessionStorage.getItem(TOKEN)

    return isAuth.value || token 
            ? ((isDefPass.value === true && Component !== ChangePassPage) 
                ? <Navigate to={CHANGE_PASS_ROUTE} /> 
                : <Component />)
            : <Navigate to={LOGIN_ROUTE} />
}

export default SiteRoutes
