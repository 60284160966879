import { useEffect } from 'react'
import { Button, Flex, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, Textarea, VStack } from '@chakra-ui/react'
import { signal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import useClients from '../../hooks/useClients'

const requestData = signal({clientId: '', name: '', topic: '', id: '', msg: ''})

const ContactModal = ({ client, invoiceList, onClose, isOpen }) => {
    useSignals()
    const {contactRequest} = useClients()
    function handleChange(e) {
        const { name, value } = e.target
        requestData.value = {...requestData.value, [name]: value}
    }

    async function handleSubmit() {
        requestData.value.clientId = client.value.id
        requestData.value.name = client.value.name
        const success = await contactRequest(requestData.value)
        if (success) {
            onClose()
        }
    }
    
    useEffect(() => {
        if (isOpen) {
            requestData.value = {clientId: '', name: '', topic: '', id: '', msg: ''}
        }
    },[isOpen])

    return(
        <Modal isCentered isOpen={isOpen} onClose={onClose} size='xl'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Solicitud de Aclaración</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <VStack w='100%'>
                        <Flex w='100%' alignItems='center'>
                            <FormLabel>Tema:</FormLabel>
                            <Input type='text' placeholder='¿Sobre qué necesitas aclaración?' name='topic' value={requestData.value.topic} onChange={handleChange}></Input>
                        </Flex>
                        <Flex w='100%'>
                            <Text mt={4} fontSize={12} as='i'>Selecciona el ID de tu factura, sólo si tu solicitud es con relación a alguna en específico.</Text>
                        </Flex>
                        <Flex w='100%' alignItems='center'>
                            <FormLabel>Factura:</FormLabel>
                            <Select value={requestData.value.id} name='id' onChange={handleChange}>
                                <option value='none'>Ninguna</option>
                                { invoiceList.value && invoiceList.value.map((invoice) => (
                                    <option key={invoice.id} value={invoice.id}>{invoice.id}</option>
                                ))}
                            </Select>
                        </Flex>
                        <Flex mt={4} w='100%'>
                            <Textarea placeholder='Ingresa tu mensaje' value={requestData.value.msg} name='msg' onChange={handleChange}/>
                        </Flex>
                    </VStack>
                </ModalBody>
                <ModalFooter gap={4}>
                    <Button className='btn' colorScheme='yellow' onClick={onClose}>Cancelar</Button>
                    <Button className='btn' colorScheme='blue' variant='outline' onClick={handleSubmit}>Solicitar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ContactModal