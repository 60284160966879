import { Box, Button, Divider, Flex, Icon, Text, useEventListener, VStack } from '@chakra-ui/react'
import { signal } from '@preact/signals-react'
import { useAuth } from '../contexts/AuthContext'
import { FaSignOutAlt, FaGoogleDrive } from 'react-icons/fa'
import { FaFileInvoiceDollar, FaUsers, FaClockRotateLeft, FaGear } from 'react-icons/fa6'
import { CLIENTS_ROUTE, CONFIG_ROUTE, DRIVE_PATH, HISTORY_ROUTE, INVOICE_ROUTE, SIDEBAR_HIDE } from '../utils/globalVars'
import NavItem from './NavItem'
import Hamburger from './Hamburger'

export const sidebarPos = signal(SIDEBAR_HIDE)

const Sidebar = () => {
    const { authLogout } = useAuth()

    useEventListener('click', e => {
        const targetClass = typeof (e.target.className) === 'string' ? e.target.className : e.target.className.baseVal
        if (!targetClass.includes('sidebar')) {
            sidebarPos.value = SIDEBAR_HIDE
        }
    },document)

    function handleHambClick() {
        sidebarPos.value = sidebarPos.value === 0 ? SIDEBAR_HIDE : 0
    }

    return (
        <>
            <Hamburger onClick={handleHambClick} />
            <Box className='sidebar' bg='blue.900' left={{ base: sidebarPos.value, lg: 0 }}>
                <VStack className='sidebar-sub' align='start' spacing={4} w='100%'>
                    <NavItem path={INVOICE_ROUTE} title='Facturación' icon={FaFileInvoiceDollar} />
                    <NavItem path={CLIENTS_ROUTE} title='Clientes'    icon={FaUsers} />
                    <NavItem path={HISTORY_ROUTE} title='Historial'   icon={FaClockRotateLeft} />
                    <NavItem path={CONFIG_ROUTE}  title='Config'      icon={FaGear} />
                    <NavItem path={DRIVE_PATH}    title='Abrir Drive' icon={FaGoogleDrive} />
                    <Divider />
                    <Flex id='logout-btn' _hover={{ backgroundColor: 'red.800' }}>
                        <Button
                            variant='link'
                            colorScheme='white'
                            _hover={{ textDecor: 'none' }}
                            onClick={authLogout}
                        >
                            <Icon as={FaSignOutAlt} fontSize='xl' color='white' mr={2} />
                            <Text>Cerrar sesión</Text>
                        </Button>
                    </Flex>
                </VStack>
            </Box>
        </>
    )
}

export default Sidebar
