import { AddIcon, DeleteIcon, EditIcon, EmailIcon, ViewIcon } from '@chakra-ui/icons'
import { Button, FormLabel, HStack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure, VStack } from '@chakra-ui/react'
import { formatDate, formatMoney } from '../utils/globalFunctions'
import DownloadPdf from './DownloadPdf'
import { FaDollarSign } from 'react-icons/fa6'
import useInvoices from '../hooks/useInvoices'
import { useSignals } from '@preact/signals-react/runtime'
import { useSignal, useComputed } from '@preact/signals-react'
import { CONFIRM_INVOICE_DEL, DATE, DEBT, ID, INVOICE_ROUTE, STATUS, TOTAL } from '../utils/globalVars'
import InvoiceModal from './modals/InvoiceModal'
import Alert from './modals/Alert'
import { useNavigate } from 'react-router-dom'
import InvoiceStatusSelect from './InvoiceStatusSelect'
import SortTable from './SortTable'
import useClients from '../hooks/useClients'

const whiteArrow = <span style={{ color: 'aliceblue' }}> ▲</span>

const InvoicesTable = ({ onReport, isAdmin, update }) => {
    useSignals()
    const navigate   = useNavigate()

    const alertBody  = useSignal('')
    const invoiceId  = useSignal(null)
    const selInvoice = useSignal({})
    const filter     = useSignal('')
    const sorter     = useSignal({key: '', dir: true})

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure()
    const { isLoading, invoiceList, clientInvoice, isEditInvoice, sendInvoice, deleteInvoice } = useInvoices()
    const { client } = useClients()

    const processedInvoices = useComputed(() => {
        let filtered = invoiceList.value
        if (filter.value) {
            filtered = filtered.filter(invoice => invoice.status === filter.value)
        }

        const {key, dir} = sorter.value
        if (key) {
            filtered = [...filtered].sort((a,b) => {
                const aVal = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
                const bVal = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];

                if (aVal < bVal) return dir ? -1 : 1
                if (aVal > bVal) return dir ? 1 : -1
                return 0
            })
        }
        return filtered
    })

    function handleFilter(e) {
        filter.value = e.target.value
    }

    function handleSort(key) {
        sorter.value = {
            key,
            dir: sorter.value.key === key ? !sorter.value.dir : true
        }
    }

    function handleView(invoice) {
        selInvoice.value = invoice
        onOpen()
    }

    function handleEdit(invoice) {
        clientInvoice.value = { invoice: invoice, client: client.value }
        isEditInvoice.value = true
        navigate(INVOICE_ROUTE)
    }

    function handleDelete(iId) {
        invoiceId.value = iId
        alertBody.value = CONFIRM_INVOICE_DEL.replace('__id__', iId)
        onAlertOpen()
    }

    async function handleAlertClose(isConfirmed) {
        if (isConfirmed) {
            await deleteInvoice(invoiceId.value)
        }
        update()
        onAlertClose()
        invoiceId.value = null
    }

    async function handleSend(id) {
        await sendInvoice(id)
    }


    return (
        <>
            {invoiceList.value && invoiceList.value.length > 0 && (
                <>
            <VStack>
                <HStack mb={{base: 2, lg: 4}}>
                    <FormLabel m={0} textAlign='right' w={70} fontWeight='bold'>Mostrar:</FormLabel>
                    <InvoiceStatusSelect value={filter.value} onChange={handleFilter} isPlural />
                </HStack>
                <SortTable sorter={sorter} parent='invoice' />
            </VStack>
            </>
            )}
            <InvoiceModal invoice={selInvoice.value} onClose={onClose} isOpen={isOpen} />
            <Alert isOpen={isAlertOpen} onClose={handleAlertClose} title='Eliminar factura' body={alertBody} />
            <TableContainer overflowY='auto' maxH='500px' minW='xs' w='100%'>
                <Table variant='striped' colorScheme='blue' size='sm' className='table' wrap='nowrap'>
                    <Thead position={'sticky'} top={0} bg={'aliceblue'} zIndex={1}>
                        <Tr>
                            <Th scope='row' className='hidden' bg='blue.900'></Th>
                            <Th scope='col' cursor='pointer' onClick={() => handleSort(ID)}>
                                ID{sorter.value.key === ID ? (sorter.value.dir ? ' ▲' : ' ▼') : whiteArrow}
                            </Th>
                            <Th scope='col' cursor='pointer' onClick={() => handleSort(TOTAL)}>
                                Importe Total{sorter.value.key === TOTAL ? (sorter.value.dir ? ' ▲' : ' ▼') : whiteArrow}
                            </Th>
                            <Th scope='col' cursor='pointer' onClick={() => handleSort(DATE)}>
                                Fecha{sorter.value.key === DATE ? (sorter.value.dir ? ' ▲' : ' ▼') : whiteArrow}
                            </Th>
                            <Th scope='col' cursor='pointer' onClick={() => handleSort(STATUS)}>
                                Estatus{sorter.value.key === STATUS ? (sorter.value.dir ? ' ▲' : ' ▼') : whiteArrow}
                            </Th>
                            <Th scope='col' cursor='pointer' onClick={() => handleSort(DEBT)}>
                                Adeudo{sorter.value.key === DEBT ? (sorter.value.dir ? ' ▲' : ' ▼') : whiteArrow}
                            </Th>
                            <Th scope='col'>Acciones</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {processedInvoices.value.map(invoice => (
                            <Tr key={invoice.id}>
                                <Th scope='row' className='hidden' bg='blue.900'></Th>
                                <Td data-header='ID'><div>{invoice.id}</div></Td>
                                <Td data-header='Importe Total'><div>{formatMoney(invoice.total)}</div></Td>
                                <Td data-header='Fecha'><div>{formatDate(invoice.date)}</div></Td>
                                <Td data-header='Estatus'><div>{invoice.status}</div></Td>
                                <Td data-header='Adeudo'><div>{formatMoney(invoice.debt)}</div></Td>
                                <Td data-header='Acciones'>
                                    <HStack spacing={2}>
                                        {!isAdmin &&
                                            <Button isDisabled={isLoading.value} title='Reportar Pago' size='sm' colorScheme='green' onClick={() => onReport(invoice)}>
                                                <AddIcon />&nbsp;<FaDollarSign />
                                            </Button>
                                        }
                                        <Button isDisabled={isLoading.value} title='Ver Detalles' size='sm' colorScheme='cyan' onClick={() => handleView(invoice)}>
                                            <ViewIcon />
                                            </Button>
                                        {isAdmin &&
                                            <>
                                                <Button size='sm' isDisabled={isLoading.value} colorScheme='orange' onClick={() => handleEdit(invoice)}><EditIcon /></Button>
                                                <Button size='sm' isDisabled={isLoading.value} colorScheme='red'    onClick={() => handleDelete(invoice.id)}><DeleteIcon /></Button>
                                                <Button size='sm' isDisabled={isLoading.value} colorScheme='green'  onClick={() => handleSend(invoice.id)}><EmailIcon /></Button>
                                            </>
                                        }
                                        <DownloadPdf size='sm' date={invoice.date} clientName={client.value.name} invoiceId={invoice.id} isTable />
                                    </HStack>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    )
}

export default InvoicesTable