import { Button, ListItem, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, UnorderedList } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { GUIDE_ROUTE } from '../../utils/globalVars'

const TermsModalContent = ({ handleClose }) => {
    const navigate = useNavigate()

    function handleClick() {
        handleClose()
        navigate(GUIDE_ROUTE)
    }
    return(
            <ModalContent>
                <ModalHeader>Términos y Condiciones</ModalHeader>
                <ModalCloseButton/>
                <ModalBody className='legal-info'>
                    <p>Mis servicios profesionales de edición de audio, aunque puedan parecer un proceso sencillo en la práctica, implican la aceptación y el cumplimiento de los términos y condiciones que se presentan a continuación. Al contratar mis servicios, te comprometes a respetar cada uno de estos términos, asegurando así una colaboración clara y eficiente.</p>
                    <br/>
                    <h1>Condiciones para Contratar mis Servicios.</h1>
                        <p>Para poder hacer uso de mis servicios de edición de audio, deberás proporcionarme los siguientes datos personales:</p>
                        <UnorderedList>
                            <ListItem>Nombre de la empresa (solo en caso de que representes a una).</ListItem>
                            <ListItem>Tu nombre y apellido.</ListItem>
                            <ListItem>Tu teléfono.</ListItem>
                            <ListItem>Tu correo electrónico.</ListItem>
                        </UnorderedList>
                    <br/>
                    <p>Utilizaré estos datos para crearte una cuenta en mi sitio, de modo que puedas hacer uso del mismo.</p>
                    <br/>
                    <h1>Política de Trabajo y Estimaciones.</h1>
                    <UnorderedList>
                        <ListItem>El trabajo se realiza bajo un principio de confianza mutua.</ListItem>
                        <ListItem>Me comprometo a realizar el trabajo lo más rápido y eficiente posible y entregar el producto terminado con alta calidad.</ListItem>
                        <ListItem>Únicamente podré proveer estimaciones una vez que se haya definido completamente el alcance del trabajo.</ListItem>
                        <ListItem>El tiempo que realmente tome llevar a cabo un trabajo podrá variar con respecto de cualquier estimación provista.</ListItem>
                    </UnorderedList>
                    <br/>
                    <h1>Forma de Cobro y Tarifa Vigente.</h1>
                    <UnorderedList>
                        <ListItem>La forma de cobro se basa en una tarifa fija por cada minuto trabajado.</ListItem>
                        <ListItem>La tarifa de edición vigente es de $3.33 MXN por minuto.</ListItem>
                        <ListItem>El cobro de un trabajo se realizará utilizando la tarifa vigente al día en que se contrata el servicio. Si la tarifa cambiara durante la realización del trabajo, este cambio no afectará el cobro del trabajo ya iniciado.</ListItem>
                        <ListItem>Cualquier cambio en la tarifa vigente será comunicado por correo electrónico a los clientes activos.</ListItem>
                    </UnorderedList>
                    <br/>
                    <h1>Métodos y Formas de Pago.</h1>
                    <UnorderedList>
                        <ListItem>Únicamente se aceptarán pagos mediante depósito o transferencia bancaria.</ListItem>
                        <ListItem>Otros métodos de pago podrán ser aceptados tras previo acuerdo.</ListItem>
                        <ListItem>Adicional al pago en una sola exhibición, podrás optar, si así lo deseas, por realizar pagos parciales hasta cubrir el monto total de tu factura, en el transcurso de un periodo de 90 días naturales a partir de la emisión de la misma.</ListItem>
                    </UnorderedList>
                    <br/>
                    <h1>Horario Laboral y Tiempos de Entrega.</h1>
                    <UnorderedList>
                        <ListItem>Mi horario laboral regular es de lunes a viernes, de 17:30 a 20:00, salvo días feriados.</ListItem>
                        <ListItem>Todos los trabajos se iniciarán a la brevedad posible, y se entregará el resultado de manera igualmente expedita.</ListItem>
                        <ListItem>Si necesitas que el trabajo se realice con urgencia, podemos acordar el que laborae en fin de semana o día feriado.</ListItem>
                        <ListItem>En caso de cualquier inconveniente que retrase la entrega, te notificaré tan pronto como sea posible.</ListItem>
                    </UnorderedList>
                    <br/>
                    <h1>Urgencia y Tarifas Especiales.</h1>
                    <UnorderedList>
                        <ListItem>Si tu proyecto es urgente y debo trabajar en él durante el fin de semana, la tarifa de ese día será el doble de la vigente.</ListItem>
                        <ListItem>Si tu proyecto es urgente y debo trabajar en él durante un día feriado, la tarifa será el triple de la vigente.</ListItem>
                        <ListItem>Sin importar la situación, me reservo el derecho de declinar tu solicitud para que labore en fin de semana o día feriado.</ListItem>
                    </UnorderedList>
                    <br/>
                    <h1>Proceso de Trabajo.</h1>
                    <h2><i>Recepción y Obtención de Material.</i></h2>
                    <UnorderedList>
                        <ListItem>Es tu responsabilidad hacerme llegar, vía correo electrónico, el material de audio o multimedia a ser editado, así como las instrucciones de edición del mismo.</ListItem>
                        <ListItem>Si no puedes proporcionarme el material directamente, puedes enviarme enlaces al mismo, y yo obtendré los archivos necesarios para editarlos. El tiempo invertido en esta actividad se verá reflejado en tu factura.</ListItem>
                        <ListItem>Si el material que me proporcionas no cumple con mis estándares de calidad, me daré a la tarea de reemplazarlo, a menos que previamente me indiques que debo utilizar exactamente el material que me proporciones.</ListItem>
                    </UnorderedList>
                    <br/>
                    <h2><i>Instrucciones de Edición y Efectos Especiales.</i></h2>
                    <UnorderedList>
                        <ListItem>Es tu responsabilidad darme instrucciones claras, completas, precisas y específicas acerca de las ediciones que necesitas.</ListItem>
                        <ListItem>Si necesitas ayuda con las instrucciones, o no sabes cómo expresar tus ideas, puedes consultar mi <Button variant='link' colorScheme='blue' onClick={handleClick}>Guía para Instrucciones</Button>. Toma en cuenta que mientras mejores sean tus instrucciones, más rápido podré terminar el trabajo.</ListItem>
                        <ListItem>En caso de que tenga alguna duda con respecto de tu proyecto, me pondré en contacto contigo inmediatamente.</ListItem>
                        <ListItem>Tal como con las istrucciones de edición, deberás darme indicaciones claras de cualquier efecto especial o transición que requieras para tu proyecto, describiendo lo más detalladamente su naturaleza y duración.</ListItem>
                    </UnorderedList>
                    <br/>
                    <h2><i>Entrega y Revisión.</i></h2>
                    <UnorderedList>
                        <ListItem>Almacenaré los resultados de tu proyecto en un servicio de almacenamiento en la nube (Google Drive, Dropbox o OneDrive), y te proporcionaré un enlace para que puedas acceder a ellos.</ListItem>
                        <ListItem>Cualquier persona que tenga el vínculo a tus archivos podrá verlos y descargarlos; es tu responsabilidad mantener la seguridad de tu enlace.</ListItem>
                        <ListItem>Tras recibir tus archivos, será tu responsabilidad el verificar que cumplan con tus requisitos y notificarme en caso de que no sea así.</ListItem>
                        <ListItem>Tendrás un periodo de 2 días hábiles para notificarme si requieres que realice algún cambio o ajuste, o si consideras que el trabajo fue terminado satisfactoriamente; tras dicho periodo, o tras indicarme que no requieres cambios, generaré tu factura.</ListItem>
                    </UnorderedList>
                    <br/>
                    <h1>Servicios Incluidos.</h1>
                    <UnorderedList>
                        <ListItem>Por defecto, mis servicios de edición incluyen la generación de tus archivos en formato MP3 de alta calidad a 256kbps; no obstante, podrás solicitar tus archivos en un formato distinto de tu elección.</ListItem>
                        <ListItem>Asimismo, mis servicios incluyen la normalización de volumen, típicamente a -12 LUFS integrados (aunque puede variar dependiendo del material de audio), para asegurarte una experiencia auditiva cohesiva entre pistas.</ListItem>
                    </UnorderedList>
                    <br/>
                    <h1>Política de Cancelaciones y Reembolsos.</h1>
                    <UnorderedList>
                        <ListItem>Podrás cancelar mis servicios en cualquier momento. Te entregaré el trabajo realizado hasta el momento de la cancelarción, y generaré tu factura con el monto de lo hasta ese momento trabajado, misma que deberás pagar sin excepciones.</ListItem>
                        <ListItem>Una vez realizado el pago de tu factura, no habrá posibilidad de reembolso.</ListItem>
                    </UnorderedList>
                    <br/>
                    <h1>Eliminación y Recuperación de Archivos.</h1>
                    <UnorderedList>
                        <ListItem>Tus archivos podrán ser eliminados sin previo aviso tras transcurrir 30 días naturales después de la entrega definitiva de los mismos.</ListItem>
                        <ListItem>Es tu responsabilidad salvaguardar tus archivos en un lugar seguro.</ListItem>
                        <ListItem>En caso de que necesites recuperar todos o algún archivo de tu proyecto, tras haber sido eliminado, podrás hacerlo dentro de un periodo de 1 año. La recuperación de los archivos ya eliminados de tu proyecto conllevará un costo del 30% del monto facturado originalmente. En caso de que sólo requieras uno o varios archivos, pero no todos, el costo por cada uno equivaldrá al 30% del monto facturado originalmente, dividido entre la cantidad total de archivos.</ListItem>
                        <ListItem>Tras transcurrir un año, la recuperación de tus archivos será imposible.</ListItem>
                    </UnorderedList>
                    <br/>
                    <h1>Inconvenientes Técnicos.</h1>
                    <UnorderedList>
                        <ListItem>Cualquier inconveniente técnico que impida la realización o finalización del trabajo te será reportado inmediatamente.</ListItem>
                        <ListItem>En caso de que no sea posible entregarte tus archivos, asimismo no te generaré factura.</ListItem>
                    </UnorderedList>
                    <br/>
                    <h1>Modificaciones a los Términos y Condiciones.</h1>
                    <UnorderedList>
                        <ListItem>Me reservo el derecho de modificar estos Términos y Condiciones en cualquier momento.</ListItem>
                        <ListItem>Te notificaré por correo electrónico cualquier cambio realizado a estos Términos y Condiciones.</ListItem>
                        <ListItem>Los cambios a estos Términos y Condiciones solamente aplicarán a proyectos nuevos.</ListItem>
                    </UnorderedList>
                    <br/>
                    <h1>Aceptación de Términos y Condiciones.</h1>
                    <p>Al contratar mis servicios profesionales, aceptas estos Términos y Condiciones, así como mi Aviso de Privacidad, y te comprometes a cumplirlos en su totalidad. Estos Términos y Condiciones constituyen un acuerdo legalmente vinculante entre tú, el cliente, y yo, el proveedor del servicio, conforme a las leyes aplicables en México.</p>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='blue' onClick={handleClose}>Entendido</Button>
                </ModalFooter>
            </ModalContent>
    )
}

export default TermsModalContent