import { useToast } from '@chakra-ui/react'
import { computed, signal, useSignal } from '@preact/signals-react'
import { useCallback, useEffect, useRef } from 'react'
import { getRatesCall, saveRatesCall } from '../services/ApiCalls'
import { showToast } from '../utils/globalFunctions'
import { useSignals } from '@preact/signals-react/runtime'
import {
    CLIENT_SITE_ROUTE,
    DEFAULT_ERROR,
    ERROR,
    GUIDE_ROUTE,
    INFO,
    LOGIN_ROUTE,
    NO_CHANGES_MSG,
    ORIGINAL_RATE,
    SUCCESS,
    TITLE_ERROR,
    TITLE_INFO,
    TITLE_SUCCESS
} from '../utils/globalVars'
import { useLocation } from 'react-router-dom'

const month  = signal(0)
const hour   = computed(() => month.value / 160)
const minute = computed(() => hour.value / 60)

export const useRates = () => {
    useSignals()
    const toast     = useToast()
    const location  = useLocation()
    const isLoading = useSignal(false)
    const isInit    = useRef(false)
    const pathname  = useRef(location.pathname)

    const fetchRates = async () => {
        isLoading.value = true
        try {
            const { status, data } = await getRatesCall()

            if (status === 200) {
                month.value = data[0]['month']
                if (!sessionStorage.getItem(ORIGINAL_RATE)) {
                    sessionStorage.setItem(ORIGINAL_RATE, month.value)
                }
                return
            }

            showToast(toast, TITLE_ERROR, data, ERROR)
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
    }

    const resetRates = () => {
        month.value = sessionStorage.getItem(ORIGINAL_RATE)
    }

    const saveRates = useCallback(async () => {
        isLoading.value = true
        try {
            const { status, data } = await saveRatesCall({
                monthly: month.value,
                hourly: hour.value,
                minutely: minute.value,
            })

            if (status === 200) {
                let msgType = SUCCESS
                let title   = TITLE_SUCCESS
                if (data.includes(NO_CHANGES_MSG)) {
                    msgType = INFO
                    title   = TITLE_INFO
                }
                showToast(toast, title, data, msgType)
                return
            }
            showToast(toast, TITLE_ERROR, data, ERROR)
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
    }, [isLoading, toast])

    const fetchRatesRef = useRef(fetchRates)

    useEffect(() => {
        if (
            !isInit.current &&
            pathname.current !== LOGIN_ROUTE &&
            pathname.current !== GUIDE_ROUTE &&
            pathname.current !== CLIENT_SITE_ROUTE
        ) {
            fetchRatesRef.current()
            isInit.current = true
        }
    }, [])

    return { isLoading, month, hour, minute, saveRates, resetRates, fetchRates }
}
