import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Box,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    VStack
} from '@chakra-ui/react'
import { formatDate, formatMoney } from '../../utils/globalFunctions'



const sortItems = (rows) => {
    if (rows !== undefined) {
        return rows.sort((a, b) => a.item_row - b.item_row)
    }
    return []
}

const InvoiceModal = ({ invoice, onClose, isOpen }) => {

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{`Detalles de la factura ${invoice && invoice.id}`}</ModalHeader>
                <ModalCloseButton onClick={onClose} />
                <ModalBody>
                    <VStack align='left' pb={4}>
                        <Flex>
                            <Text fontWeight='bold'>Fecha:</Text>
                            <Text ml={2}>{invoice && formatDate(invoice.date)}</Text>
                        </Flex>
                        <Flex>
                            <Text fontWeight='bold'>Estatus:</Text>
                            <Text ml={2}>{invoice && invoice.status}</Text>
                        </Flex>
                        {invoice && invoice.status === 'Parcial' &&
                            <Flex>
                                <Text fontWeight='bold'>Adeudo:</Text>
                                <Text ml={2}>{invoice && formatMoney(invoice.debt)}</Text>
                            </Flex>
                        }
                    </VStack>
                    <Box overflowX='auto'>
                        <Table variant='simple' size='sm'>
                            <Thead>
                                <Tr>
                                    <Th p={2} className='hidden'></Th>
                                    <Th p={2}>Descripción</Th>
                                    <Th p={2} textAlign='center' whiteSpace='nowrap'>Tiempo (min)</Th>
                                    <Th p={2} textAlign='right'>Importe</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {invoice && sortItems(invoice.items).map((item, index) => (
                                    <Tr key={index}>
                                        <Td p={2} className='hidden'></Td>
                                        <Td p={2}>{item.description}</Td>
                                        <Td p={2} textAlign='center'>{item.time_spent}</Td>
                                        <Td p={2} textAlign='right' whiteSpace='nowrap'>{formatMoney(item.subtotal)}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Flex justify='flex-end' flex={1} gap={4}>
                        <Text fontSize='lg' fontWeight='bold' p={2}>Total: </Text>
                        <Text fontSize='lg' fontWeight='bold' p={2}>{invoice && formatMoney(invoice.total)}</Text>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default InvoiceModal