import { HStack, FormLabel, Select, Button } from '@chakra-ui/react'

const SortTable = ({ sorter, parent }) => {

    function handleClick() {
        sorter.value = { ...sorter.value, dir: !sorter.value.dir }
    }

    function handleChange(e) {
        sorter.value = { ...sorter.value, key: e.target.value }
    }

    return (
        <HStack mb={4} display={{ base: 'flex', lg: 'none' }} w='100%'>
            <FormLabel className='narrow-lbl' m={0} textAlign='right' fontWeight='bold'>Ordenar:</FormLabel>
            <Select name='type' value={sorter.value.key} onChange={(handleChange)} placeholder='Columna...'>
                {parent === 'client'
                    ? (
                        <>
                            <option value='id'>ID</option>
                            <option value='type'>Tipo</option>
                            <option value='name'>Nombre</option>
                        </>
                    )
                    : (
                        <>
                            <option value='id'>ID</option>
                            <option value='total'>Importe Total</option>
                            <option value='date'>Fecha</option>
                            <option value='status'>Estatus</option>
                            <option value='debt'>Adeudo</option>
                        </>
                    )}
            </Select>
            <Button colorScheme='blue' onClick={handleClick}>{sorter && sorter.value.dir ? '▲' : '▼'}</Button>
        </HStack>
    )
}

export default SortTable