import { useRef } from 'react'
import { Box, Button, Input, Stack } from '@chakra-ui/react'
import { useLogin } from '../hooks/useLogin'
import { useNavigate } from 'react-router-dom'
import { useSignals } from '@preact/signals-react/runtime'


const LoginForm = () => {
  useSignals()
  const navigate = useNavigate()

  const usernameRef = useRef()
  const passwordRef = useRef()
  const { login, isLoading } = useLogin()

  async function handleSubmit(e) {
    e.preventDefault()
    const username = usernameRef.current.value
    const password = passwordRef.current.value
    const success  = await login({username, password})
    if (success) navigate('/')
  }  


  return(
    <Box as='form' onSubmit={handleSubmit} w='100%'>
      <Stack spacing={3}>
          <Input ref={usernameRef} autoComplete='username' type='text' placeholder='Nombre de usuario' required />
          <Input ref={passwordRef} autoComplete='current-password' type='password' placeholder='Contraseña' required />
        <Button type='submit' colorScheme='blue' isLoading={isLoading.value}>Entrar</Button>
      </Stack>
    </Box>
  )
}

export default LoginForm
