import { Modal, ModalOverlay } from '@chakra-ui/react'
import PrivacyModalContent from './PrivacyModalContent'
import TermsModalContent from './TermsModalContent'
import { PRIVACY_USECASE } from '../../utils/globalVars'

const FooterModal = ({ isActive, handleClose, useCase }) => {

    return (
        <Modal isOpen={isActive} onClose={handleClose} size='xl' scrollBehavior='inside'>
            <ModalOverlay />
            {useCase === PRIVACY_USECASE ? <PrivacyModalContent handleClose={handleClose} /> : <TermsModalContent handleClose={handleClose} />}
        </Modal>
    )
}

export default FooterModal