import { useToast } from "@chakra-ui/react"
import { useSignals } from "@preact/signals-react/runtime"
import { useSignal } from '@preact/signals-react'
import { useCallback } from "react"
import { showToast } from "../utils/globalFunctions"
import { forgotPasswordCall, restorePasswordCall, updatePasswordCall } from "../services/ApiCalls"
import { BLANK_SPACE_IN_PASS, 
    CHANGE_USECASE, 
    CRITERIA_ERROR, 
    DEF_PASS, 
    DEFAULT_ERROR, 
    ERROR, 
    NO_PASS_MATCH,
    RESET_PASS_ERROR, 
    RESTORE_USECASE, 
    SUCCESS, 
    TITLE_ERROR, 
    TITLE_SUCCESS, 
    TOKEN_NOT_FOUND, 
    USER_ID, 
    USER_ID_NOT_FOUND 
} from "../utils/globalVars"

export const usePassword = () => {
    useSignals()
    const toast     = useToast()
    const isLoading = useSignal()

    const changePassword = useCallback(async (password, match, criteria) => {
        isLoading.value = true
        try {
            const userId = sessionStorage.getItem(USER_ID)

            const [isValid, message] = verifyData(password, match, criteria, null, userId, CHANGE_USECASE)
            if (!isValid) {
                showToast(toast, TITLE_ERROR, message, ERROR)
                return false
            }

            const { status, data } = await updatePasswordCall({ id: userId, password })
            if (status === 200) {
                showToast(toast, TITLE_SUCCESS, data, SUCCESS)
                sessionStorage.removeItem(DEF_PASS)
                return true
            }
            showToast(toast, TITLE_ERROR, data, ERROR)
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return false
    }, [toast, isLoading])

    const forgotPassword = useCallback(async (username, email) => {
        if (!username || !email) {
            showToast(toast, TITLE_ERROR, RESET_PASS_ERROR, ERROR)
            return false
        }
        isLoading.value = true
        try {
            const { status, data } = await forgotPasswordCall(username, email)
            if (status === 200) {
                showToast(toast, TITLE_SUCCESS, data, SUCCESS)
                return true
            }
            showToast(toast, TITLE_ERROR, data, ERROR)
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return false
    }, [toast, isLoading])

    const restorePassword = useCallback(async (password, match, criteria, token) => {
        isLoading.value = true
        try {
            const [isValid, message] = verifyData(password, match, criteria, token, null, RESTORE_USECASE)
            if (!isValid) {
                showToast(toast, TITLE_ERROR, message, ERROR)
                return false
            }

            const { status, data } = await restorePasswordCall({ token: token, password: password })
            if (status === 200) {
                showToast(toast, TITLE_SUCCESS, data, SUCCESS)
                return true
            }
            showToast(toast, TITLE_ERROR, data, ERROR)
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return false
    }, [toast, isLoading])

    function verifyData(password, match, criteria, token, userId, useCase) {
        if (password && /\s/.test(password)) {
            return [false, BLANK_SPACE_IN_PASS]
        }
        if (match === false) {
            return [false, NO_PASS_MATCH]
        }
        if (criteria && !Object.values(criteria).every(criterion => criterion.value)) {
            return [false, CRITERIA_ERROR]
        }
        if (useCase === CHANGE_USECASE && (userId === null || userId === undefined)) {
            return [false, USER_ID_NOT_FOUND]
        }
        if (useCase === RESTORE_USECASE && (!token)) {
            return [false, TOKEN_NOT_FOUND]
        }
        return [true, null]
    }

    return {
        isLoading,
        changePassword,
        forgotPassword,
        restorePassword,
    }
}