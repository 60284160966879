import { signal, useSignal } from '@preact/signals-react'
import { useToast } from "@chakra-ui/react"
import { showToast } from "../utils/globalFunctions"
import { useCallback } from 'react'
import { deleteInvoiceCall, 
    downloadInvoiceCall, 
    getInvoicesCall, 
    reportPaymentCall, 
    saveInvoiceCall, 
    sendInvoiceCall, 
    updateInvoiceCall 
} from '../services/ApiCalls'
import { DEFAULT_ERROR, 
    ERROR, 
    INFO, 
    NO_AMOUNT_FOUND, 
    NO_CLIENT_SELECTED, 
    NO_INVOICE_ID, 
    NO_INVOICES_FOUND, 
    NO_ITEMS_FOUND, 
    NO_TIME_FOUND, 
    SUCCESS, 
    TITLE_ERROR, 
    TITLE_INFO, 
    TITLE_SUCCESS
} from '../utils/globalVars'

const blankInvoice = { clientId: null, status: 'Pendiente', date: new Date().toISOString().split('T')[0], debt: null, total: null, items: [] }
const blankClient  = { id: null, name: '' }

const invoiceList    = signal([])
const clientInvoice  = signal(null)
const isEditInvoice  = signal(false)
const currentInvoice = signal(blankInvoice)

const useInvoices = () => {
    const toast = useToast()
    const isLoading = useSignal(false)

    const getInvoices = useCallback(async (clientId) => {
        isLoading.value = true
        try {
            const { status, data } = await getInvoicesCall(clientId)
            if (status === 200) {
                invoiceList.value = data
                return true
            } else if (status === 204) {
                showToast(toast, TITLE_INFO, NO_INVOICES_FOUND, INFO)
                return false
            }
            showToast(toast, TITLE_ERROR, data, ERROR)
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return false
    }, [isLoading, toast])

    const reportPayment = useCallback(async (invoiceId, payment) => {
        isLoading.value = true
        try {
            const { status, data } = await reportPaymentCall({ id: invoiceId, payment: payment })
            if (status === 202) {
                showToast(toast, TITLE_SUCCESS, data, SUCCESS)
                return true
            }
            showToast(toast, TITLE_ERROR, data, ERROR)
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return false
    }, [toast, isLoading])

    const deleteInvoice = useCallback(async (invoiceId) => {
        isLoading.value = true
        try {
            const { status, data } = await deleteInvoiceCall(invoiceId)
            if (status === 200) {
                invoiceList.value = invoiceList.value.filter(invoice => invoice.id !== invoiceId)
                showToast(toast, TITLE_SUCCESS, data, SUCCESS)
                return
            }
            showToast(toast, TITLE_ERROR, data, ERROR)
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return
    }, [isLoading, toast])

    const saveInvoice = useCallback(async (inputData) => {
        isLoading.value = true
        try {
            const { status, data } = await saveInvoiceCall(inputData)
            if (status === 201) {
                showToast(toast, TITLE_SUCCESS, data.split('|')[0], SUCCESS)
                return (data.split(': ')[1]).split('|')
            }
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return
    }, [isLoading, toast])

    const updateInvoice = useCallback(async (inputData) => {
        isLoading.value = true
        try {
            const { status, data } = await updateInvoiceCall(inputData)
            if (status === 200) {
                const dataArray = data.split('|')
                showToast(toast, TITLE_SUCCESS, dataArray[0], SUCCESS)
                return dataArray[1]
            }
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return
    }, [isLoading, toast])

    function setInvoiceBody(isUpdate, id, client, items, data) {
        if (JSON.stringify(client) === JSON.stringify(blankClient)) {
            showToast(toast, TITLE_ERROR, NO_CLIENT_SELECTED, ERROR)
            return null
        }

        if (isUpdate && id === null) {
            showToast(toast, TITLE_ERROR, NO_INVOICE_ID, ERROR)
            return null
        }
        if (!verifyClient(client) || !verifyItems(items) || !verifyData(data)) {
            return null
        }

        const itemsList = setItems(items)
        let body = null
        if (isUpdate) {
            body = {
                invoiceId: id,
                clientId: client.id,
                status: data.status,
                date: data.date,
                debt: data.debt,
                total: data.total,
                items: itemsList
            }
        } else {
            body = {
                clientId: client.id,
                status: data.status,
                date: data.date,
                debt: data.debt,
                total: data.total,
                items: itemsList
            }
        }

        return body
    }

    function setItems(items) {
        const itemsList = []

        items.forEach(item => {
            if (item.acn !== '') {
                itemsList.push({
                    id: Number(item.acn),
                    description: item.description.trim(),
                    timeSpent: Number(item.time),
                    subtotal: Math.round((Number(item.amount) + Number.EPSILON) * 100) / 100
                })
            } else {
                itemsList.push({
                    description: item.description.trim(),
                    timeSpent: Number(item.time),
                    subtotal: Math.round((Number(item.amount) + Number.EPSILON) * 100) / 100
                })
            }
        });
        return itemsList
    }

    function verifyClient(client) {
        const props = [['id', 'ID'], ['name', 'Nombre'], ['contact', 'Contacto'], ['type', 'Tipo'], ['phone', 'Teléfono'], ['email', 'Email']]
        let hasErrors = false
        props.every(prop => {
            if (!Object.hasOwn(client, prop[0])) {
                showToast(toast, TITLE_ERROR, `Datos de cliente incompletos: ${prop[1]}`, ERROR)
                hasErrors = true
                return false
            }
            return true
        })

        return !hasErrors
    }

    function verifyItems(items) {
        let hasErrors = false
        items.every(item => {
            if (item.description === '') {
                hasErrors = true
                showToast(toast, TITLE_ERROR, NO_ITEMS_FOUND, ERROR)
                return false
            } else if (item.time === '') {
                showToast(toast, TITLE_ERROR, NO_TIME_FOUND, ERROR)
                hasErrors = true
                return false
            } else if (item.amount === '') {
                showToast(toast, TITLE_ERROR, NO_AMOUNT_FOUND, ERROR)
                hasErrors = true
                return false
            }
            return true
        })

        return !hasErrors
    }

    function verifyData(data) {
        let hasErrors = false
        if (data.status !== 'Pendiente' && data.status !== 'Parcial' && data.status !== 'Pagada') {
            showToast(toast, TITLE_ERROR, 'Estatus de factura no válido', ERROR)
            hasErrors = true
        } else if (data.payment < 0) {
            showToast(toast, TITLE_ERROR, 'Monto de pago no válido', ERROR)
            hasErrors = true
        } else if (data.totalTime < 0) {
            showToast(toast, TITLE_ERROR, 'Tiempo total no válido', ERROR)
            hasErrors = true
        } else if (!isValidDate(data.date)) {
            showToast(toast, TITLE_ERROR, 'Fecha no válida', ERROR)
            hasErrors = true
        } else if (data.total < 0) {
            showToast(toast, TITLE_ERROR, 'Importe Total no válido', ERROR)
            hasErrors = true
        } else if (data.debt < 0) {
            showToast(toast, TITLE_ERROR, 'Adeudo no válido', ERROR)
            hasErrors = true
        }

        return !hasErrors
    }

    function isValidDate(dateString) {
        var regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateString.match(regEx)) return false;  // Invalid format
        var d = new Date(dateString);
        var dNum = d.getTime();
        if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
        return d.toISOString().slice(0, 10) === dateString;
    }

    const printInvoice = useCallback(async (invoiceId, clientName, date) => {
        isLoading.value = true
        try {
            const res = await downloadInvoiceCall(invoiceId)
            const blob = new Blob([res.data], { type: 'application/pdf' });

            date = (date.split(' ')[0]).replace(/-/gi, '')
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            const fileName = `${clientName.toLowerCase().replace(/ /g, '_')}_${date}.pdf`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            showToast(toast, TITLE_ERROR, 'Error al intentar descargar la factura', ERROR)
        } finally {
            isLoading.value = false
        }

    }, [isLoading, toast])

    const sendInvoice = useCallback(async (invoiceId) => {
        isLoading.value = true
        try {
            const { status, data } = await sendInvoiceCall(invoiceId)
            if (status === 200) {
                showToast(toast, TITLE_SUCCESS, data, SUCCESS)
                return
            }
            showToast(toast, TITLE_ERROR, data, ERROR)
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return
    }, [toast, isLoading])

    return {
        invoiceList,
        getInvoices,
        deleteInvoice,
        saveInvoice,
        updateInvoice,
        setInvoiceBody,
        printInvoice,
        reportPayment,
        sendInvoice,
        isLoading,
        clientInvoice,
        isEditInvoice,
        currentInvoice,
    }
}

export default useInvoices