import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { Button, FormLabel, HStack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure, VStack } from '@chakra-ui/react'
import { FaUserPlus, FaFileAudio } from 'react-icons/fa6'
import useClients from '../hooks/useClients'
import { useComputed, useSignals } from '@preact/signals-react/runtime'
import { useSignal } from '@preact/signals-react'
import ClientTypeSelect from './ClientTypeSelect'
import { CONFIRM_CLIENT_DEL, 
    CREATE_USER_ASK, 
    ID, 
    NAME, 
    NOTIFY_CLIENT_ASK, 
    TITLE_CREATE_USER, 
    TITLE_DEL_CLIENT, 
    TITLE_NOTIFY_CLIENT, 
    TYPE 
} from '../utils/globalVars'
import SortTable from './SortTable'
import Alert from './modals/Alert'
import ClientModal from './modals/ClientModal'

const whiteArrow = <span style={{ color: 'aliceblue' }}> ▲</span>

const ClientsTable = ({ nameFilter, typeFilter, openNewClientModal }) => {
    useSignals()

    const { createUser, notifyAddedFiles, notifyChange, deleteClient, clientsList, client } = useClients()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()

    const alertInfo  = useSignal({ title: '', body: '' })
    const sorter     = useSignal({ key: '', dir: true })

    const processedClients = useComputed(() => {
        let filtered = clientsList.value
        if (typeFilter.value || nameFilter.value) {
            filtered = filtered.filter(client => {
                const nameMatches = nameFilter.value === '' || client.name.toLowerCase().includes(nameFilter.value.toLowerCase())
                const typeMatches = typeFilter.value === '' || client.type === typeFilter.value
                return nameMatches && typeMatches
            })
        }

        const { key, dir } = sorter.value
        if (key) {
            filtered = [...filtered].sort((a, b) => {
                const aVal = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key]
                const bVal = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key]

                if (aVal < bVal) return dir ? -1 : 1
                if (aVal > bVal) return dir ? 1 : -1
                return 0
            })
        }
        return filtered
    })

    function handleTypeFilter(e) {
        typeFilter.value = e.target.value
    }

    const handleSort = (key) => {
        sorter.value = {
            key,
            dir: sorter.value.key === key ? !sorter.value.dir : true
        }
    }

    async function handleNewUser(cl) {
        client.value = cl
        alertInfo.value = { title: TITLE_CREATE_USER, body: CREATE_USER_ASK}
        onOpen()
    }

    async function notifyFiles(clientId) {
        await notifyAddedFiles(clientId)
    }

    async function handleAlertClose(actionConfirmed) {
        if (!actionConfirmed) {
            onClose()
            return
        }

        const { title } = alertInfo.value
        const clientId = client.value.id

        switch (title) {
            case TITLE_CREATE_USER:
                await createUser(clientId)
                break
            case TITLE_NOTIFY_CLIENT:
                await notifyChange(clientId)
                break
            default:
                await deleteClient(clientId)
                client.value = { id: null, name: '' }
        }

        onClose()
    }

    function handleDelete(cl) {
        client.value = cl
        const body = CONFIRM_CLIENT_DEL.replace('__name__', cl.name).replace('__id__',cl.id)
        alertInfo.value = { title: TITLE_DEL_CLIENT, body: body}
        onOpen()
    }

    function handleModalClose() {
        openNewClientModal.value = false
        onModalClose()

        if (!client.value) return;

        const { name } = client.value;
        if (name === 'new') {
            alertInfo.value = {title: TITLE_CREATE_USER, body: CREATE_USER_ASK}
            onOpen()
            return
        }
        if ('changed' in client.value) {
            alertInfo.value = {title: TITLE_NOTIFY_CLIENT, body: NOTIFY_CLIENT_ASK}
            onOpen()
        }
        openNewClientModal.value = false
    }

    function handleEdit(cl) {
        client.value = cl
        onModalOpen()
    }

    return (
        <>
            <ClientModal isOpen={isModalOpen || openNewClientModal.value} onClose={handleModalClose} client={client} />
            <Alert isOpen={isOpen} onClose={handleAlertClose} title={alertInfo.value.title} body={alertInfo.value.body} />
            <VStack alignItems='center' flexDir='column' maxW={{sm: '440px', md: '520px', }} width='100%'>
                <HStack w='100%' mb={{ base: 0, lg: 4 }}>
                    <FormLabel className='narrow-lbl' m={0} fontWeight='bold' textAlign='right'>Mostrar:</FormLabel>
                    <ClientTypeSelect value={typeFilter.value} onChange={handleTypeFilter} isPlural />
                </HStack>
                <SortTable sorter={sorter} parent='client' />
            </VStack>
            <TableContainer w='100%' maxH='500px' minW={'xs'} overflowY='auto'>
                <Table variant='striped' colorScheme='blue' size='sm' className='table' wrap='wrap'>
                    <Thead position={'sticky'} top={0} bg={'aliceblue'} zIndex={1}>
                        <Tr>
                            <Th scope='row' className='hidden' bg='blue.900'></Th>
                            <Th scope='col' cursor='pointer' onClick={() => handleSort(ID)}>
                                ID{sorter.value.key === ID ? (sorter.value.dir ? ' ▲' : ' ▼') : whiteArrow}
                            </Th>
                            <Th scope='col' cursor='pointer' onClick={() => handleSort(TYPE)}>
                                Tipo{sorter.value.key === TYPE ? (sorter.value.dir ? ' ▲' : ' ▼') : whiteArrow}
                            </Th>
                            <Th scope='col' cursor='pointer' onClick={() => handleSort(NAME)}>
                                Nombre{sorter.value.key === NAME ? (sorter.value.dir ? ' ▲' : ' ▼') : whiteArrow}
                            </Th>
                            <Th scope='col'>Teléfono</Th>
                            <Th scope='col' minW='190px' maxW={{ base: '100%', lg: '220px' }}>Email</Th>
                            <Th scope='col'>Contacto</Th>
                            <Th scope='col'>Acciones</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {processedClients.value.map(client => (
                            <Tr key={client.id}>
                                <Th scope='row' className='hidden' bg='blue.900'></Th>
                                <Td data-header='ID'><div>{client.id}</div></Td>
                                <Td data-header='Tipo'><div>{client.type}</div></Td>
                                <Td data-header='Nombre'><div>{client.name}</div></Td>
                                <Td data-header='Teléfono'><div>{client.phone}</div></Td>
                                <Td data-header='Email' minW='190px' maxW={{ base: '100%', lg: '220px' }}><div style={{ textWrap: 'balance', overflowWrap: 'anywhere', minW: '190px', maxW: '220px' }}>{client.email}</div></Td>
                                <Td data-header='Contacto'><div>{client.contact !== null && client.contact !== undefined && client.contact.length > 0 ? client.contact : 'N/A'}</div></Td>
                                <Td data-header='Acciones'>
                                    <HStack spacing={2}>
                                        <Button size='sm' colorScheme='green'  onClick={() => handleEdit(client)}><EditIcon /></Button>
                                        <Button size='sm' colorScheme='red'    onClick={() => handleDelete(client)}><DeleteIcon /></Button>
                                        <Button size='sm' colorScheme='blue'   onClick={() => handleNewUser(client)}><FaUserPlus /></Button>
                                        <Button size='sm' colorScheme='purple' onClick={() => notifyFiles(client.id)}><FaFileAudio /></Button>
                                    </HStack>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ClientsTable