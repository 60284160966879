import { EMPRESA } from './globalVars'

export function formatMoney(value) {
    const numberValue = parseFloat(value)
    if (isNaN(numberValue)) return '$ 0.00'
    return numberValue.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }).replace('$', '$ ')
}

export function showToast(toast, title, msg, type) {
    toast({
        title: title,
        description: msg,
        position: 'top',
        status: type,
        duration: 4000,
        isClosable: true
    })
}

export function isValidClient(client) {
    const { type, name, phone, email, contact } = client

    if (!type || !name || !phone || !email) return false
    if (type === EMPRESA && !contact)       return false

    return true
}


export function formatDate(dateString) {
    const date  = new Date(dateString)
    const day   = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year  = date.getFullYear()

    return `${day}/${month}/${year}`
}

export function onlyNumbersInput(e) {
    if (e.key === '-' || e.key === 'e' || e.key === '+') {
        e.preventDefault()
    }
}
