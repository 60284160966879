import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { AuthProvider } from './contexts/AuthContext'
import './styles/styles.css'
import Logo from './components/Logo'
import App from './App'
import PageFooter from './components/PageFooter'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <StrictMode>
        <ChakraProvider>
            <AuthProvider>
                <BrowserRouter>
                    <div className='app-container'>
                        <Logo />
                        <App />
                        <PageFooter />
                    </div>
                </BrowserRouter>
            </AuthProvider>
        </ChakraProvider>
    </StrictMode>
)

// to run PHP backend: php -S 127.0.0.1:8000 -t public
/**

 * LASTLY: deploy Backend
 *         build and deploy Frontend
 *         verify environment variables
 *         test every feature
 *         test every feautre in mobile
 *         test every feature with slow connection in both desktop and mobile
 */
