import logo from '../assets/logo.png'
import { Box, Center, Image } from '@chakra-ui/react'
import { useAuth } from '../contexts/AuthContext'
import { useSignals } from '@preact/signals-react/runtime'
import { SIDEBAR_ML } from '../utils/globalVars'

const Logo = () => {
    useSignals()
    const { isAuth, isDefPass, userId } = useAuth()
    return (
        <>
            <Box p={5} bg='none' ml={{ base: 0, lg: (isAuth.value && !isDefPass.value && userId.value === 1) ? SIDEBAR_ML : 0 }}>
                <Center>
                    <Image
                        src={logo}
                        alt='AM Music & Sound Design'
                        maxH='200px'
                    />
                </Center>
            </Box>
        </>
    )
}

export default Logo
