import { Button, Checkbox, Container, Flex, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { signal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import { onlyNumbersInput } from '../../utils/globalFunctions'

const payment = signal('')
const isAdded = signal(false)
const isTotal = signal(false)

const PaymentModal = ({isOpen, onClose, isConfirmed, isClient }) => {
    useSignals()

    function handleClose() {
        payment.value = ''
        isAdded.value = false
        isTotal.value = false
        onClose()
    }

    function handleConfirm() {
        if (isTotal.value) {
            isConfirmed.value = {pay: -1, added: isAdded.value}
        } else {
            isConfirmed.value = {pay: Number(payment.value), added: isAdded.value}
        }
        onClose()
        payment.value = ''
        isAdded.value = false
        isTotal.value = false
    }

    function handleIsTotal() {
        if (!isTotal.value) {
            payment.value = ''
        }
        isTotal.value = !isTotal.value
    }

    return(
        <Modal isCentered isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{isClient ? 'Reportar Pago' : 'Agregar Pago'}</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Flex gap={4} alignItems='center'>
                        <FormLabel minW='fit-content' m={0}>Pago realizado:</FormLabel>
                        <Container>
                        <Input isDisabled={isTotal.value} type='number' min={0} value={payment.value} onChange={(e) => payment.value = e.target.value} onKeyDown={onlyNumbersInput} placeholder='0.00'/>

                        </Container>
                    </Flex>
                    <Container pt={4}>
                        <Checkbox isChecked={isTotal.value} onChange={handleIsTotal}>Pagué el total de la factura.</Checkbox>
                    </Container>
                    {!isClient &&
                        <Container>
                            <Checkbox isChecked={isAdded.value} onChange={() => isAdded.value = !isAdded.value}>Suma al pago ya hecho</Checkbox>
                        </Container>
                    }
                </ModalBody>
                <ModalFooter>
                    <HStack>
                        <Button colorScheme='yellow' onClick={handleClose}>Cancelar</Button>
                        <Button colorScheme='blue' variant='outline' onClick={handleConfirm}>{isClient ? 'Reportar' : 'Agregar'}</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default PaymentModal