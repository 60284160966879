import { Button } from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'

const Hamburger = ({ onClick }) => {
    return (
        <Button
            id='hamb'
            className='sidebar-btn'
            title='hamburger'
            display={{ base: 'block', lg: 'none' }}
            onClick={onClick}
            borderColor='blue.700'
        >
            <HamburgerIcon className='sidebar-hamb' />
        </Button>
    )
}

export default Hamburger
