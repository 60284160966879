import { Flex, Icon, Link, Menu, MenuButton, Text } from '@chakra-ui/react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

const NavItem = ({ path, title, icon }) => {
    const location = useLocation()

    return (
        <Flex className='sidebar-flex' alignItems='center' w='100%' minH='50px'>
            <Menu>
                <Link
                    className='side-link'
                    as={RouterLink}
                    to={path}
                    target={title === 'Abrir Drive' ? '_blank' : '_self'}
                    bgColor={location.pathname === path && 'blue.400'}
                    _hover={{ textDecor: 'none', backgroundColor: 'blue.600' }}
                >
                    <MenuButton w='100%' type='button'>
                        <Flex alignItems='center' gap={2}>
                            <Icon as={icon} fontSize='xl' color='white' />
                            <Text>{title}</Text>
                        </Flex>
                    </MenuButton>
                </Link>
            </Menu>
        </Flex>
    )
}

export default NavItem
