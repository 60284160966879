import { Button, useBreakpointValue, useToast } from '@chakra-ui/react'
import { DownloadIcon } from '@chakra-ui/icons'
import { showToast } from '../utils/globalFunctions'
import useInvoices from '../hooks/useInvoices'
import { ERROR, TITLE_ERROR, NO_INVOICE_SELECTED, NO_CLIENT_SELECTED } from '../utils/globalVars'

const DownloadPdf = ({ size, date, clientName, invoiceId, isTable }) => {
    const toast = useToast()
    const { printInvoice, isLoading } = useInvoices()
    const isWide = useBreakpointValue({ base: false, sm: true })

    async function handleClick() {
        const validations = [
            { condition: !invoiceId, message: NO_INVOICE_SELECTED },
            { condition: !clientName, message: NO_CLIENT_SELECTED },
        ]

        for (const { condition, message } of validations) {
            if (condition) {
                showToast(toast, TITLE_ERROR, message, ERROR);
                return
            }
        }

        await printInvoice(invoiceId, clientName, date)
    }


    return (
        <Button 
            title='Descargar' 
            isDisabled={isLoading.value} 
            size={size && size} 
            colorScheme='blue' 
            onClick={handleClick}
        >
            {isWide && !isTable ? 'Descargar' : <DownloadIcon />}
        </Button>
    )
}

export default DownloadPdf
