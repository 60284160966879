import { useEffect, useRef } from 'react'
import { useSignal } from '@preact/signals-react'
import { useComputed, useSignals } from '@preact/signals-react/runtime'
import { Box, List, ListItem, Input, useOutsideClick } from '@chakra-ui/react'
import useClients from '../hooks/useClients'

const ClientSearchDropdown = ({ inputValue, onSelect }) => {
    useSignals()
    const { clientsList } = useClients()
    const isDropdownOpen  = useSignal(false)
    const selectedIndex   = useSignal(-1)

    const listRef     = useRef()
    const inputRef    = useRef()
    const dropdownRef = useRef()

    const filteredClients = useComputed(() => 
        inputValue.value ? clientsList.value.filter(client => client.name.toLowerCase().includes(inputValue.value.toLowerCase()) ) : clientsList.value
    )

    useOutsideClick({
        ref: dropdownRef,
        handler: () => isDropdownOpen.value = false
    })

    useEffect(() => {
        if (selectedIndex.value >= 0 && listRef.current) {
            const listItem = listRef.current.children[selectedIndex.value]
            if (listItem) {
                listItem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                })
            }
        }
    }, [selectedIndex.value])

    const handleKeyDown = (e) => {
        const clientsLength = filteredClients.value.length;
        if (clientsLength === 0) return;
    
        switch (e.key) {
            case 'ArrowDown':
                selectedIndex.value = (selectedIndex.value + 1) % clientsLength;
                break;
            case 'ArrowUp':
                selectedIndex.value = (selectedIndex.value - 1 + clientsLength) % clientsLength;
                break;
            case 'Enter':
                if (selectedIndex.value >= 0) {
                    handleSelect(filteredClients.value[selectedIndex.value]);
                }
                break;
            default:
                break;
        }
    };

    function handleSelect(client) {
        inputValue.value = ''
        isDropdownOpen.value = false
        onSelect(client)
    }

    function handleChange(e) {
        inputValue.value = e.target.value
        isDropdownOpen.value = true
    }

    return (
        <Box ref={dropdownRef} position='relative' width='100%'>
            <Input
                ref={inputRef}
                value={inputValue.value}
                onChange={handleChange}
                onFocus={() => isDropdownOpen.value = true}
                onKeyDown={handleKeyDown}
                placeholder='Buscar cliente...'
            />
            {isDropdownOpen.value && filteredClients.value.length > 0 && (
                <List ref={listRef} className='clients-dropdown'>
                    {filteredClients.value.map((client, index) => (
                        <ListItem
                            key={client.id}
                            p='8px'
                            bg={index === selectedIndex.value ? 'blue.100' : 'white'}
                            cursor='pointer'
                            onClick={() => handleSelect(client)}
                            onMouseEnter={() => selectedIndex.value = index}
                        >{client.name}
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>
    )
}

export default ClientSearchDropdown
