import { Select } from '@chakra-ui/react'

const InvoiceStatusSelect = ({ value, onChange, isPlural, isDisabled }) => {
    return (
        <Select maxW='fit-content' name='status' title='status' value={value} onChange={onChange} isDisabled={isDisabled}>
            {isPlural && <option value=''>Todas</option>}
            <option value='Pendiente'>{isPlural ? 'Pendientes' : 'Pendiente'}</option>
            <option value='Parcial'>{isPlural ? 'Pagadas parcialmente' : 'Parcial'}</option>
            <option value='Pagada'>{isPlural ? 'Pagadas' : 'Pagada'}</option>
        </Select>
    )
}

export default InvoiceStatusSelect