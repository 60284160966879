import { Box, ChakraProvider, Flex } from '@chakra-ui/react'
import { AuthProvider, useAuth } from './contexts/AuthContext'
import { useSignals } from '@preact/signals-react/runtime'
import { SIDEBAR_ML } from './utils/globalVars'
import Sidebar from './components/Sidebar'
import SiteRoutes from './SiteRoutes'

const App = () => {
    useSignals()
    const { isAuth, userId, isDefPass } = useAuth()

    return (
        <ChakraProvider>
            <AuthProvider>
                <Flex>
                    {isAuth.value && userId.value === 1 && <Sidebar />}
                    <Box flex={1} p={4} ml={{ base: 0, lg: (isAuth.value && !isDefPass.value && userId.value === 1) ? SIDEBAR_ML : 0 }}>
                        <SiteRoutes />
                    </Box>
                </Flex>
            </AuthProvider>
        </ChakraProvider>
    )
}

export default App
