import { createContext, useContext, useEffect } from 'react'
import { useSignals } from '@preact/signals-react/runtime'
import { signal } from '@preact/signals-react'
import { CLIENT_ID, DEF_PASS, ORIGINAL_RATE, TOKEN, USER_ID } from '../utils/globalVars'
//import { 'clientId', 'isDefPass', 'token', 'userId' } from '../utils/globalVars'

const AuthContext = createContext()

const isAuth    = signal(false)
const userId    = signal(null)
const clientId  = signal(null)
const isDefPass = signal(false)

export const AuthProvider = ({ children }) => {
    useSignals()
    
    useEffect(() => {
        const token           = sessionStorage.getItem(TOKEN)
        const storedUserId    = sessionStorage.getItem(USER_ID)
        const storedClientId  = sessionStorage.getItem(CLIENT_ID)
        const storedIsDefPass = sessionStorage.getItem(DEF_PASS)

        if (token && storedUserId) {
            isAuth.value    = true
            userId.value    = Number(storedUserId)
            clientId.value  = Number(storedClientId)
            isDefPass.value = storedIsDefPass === 'true'
        }
    }, [])

    const authLogin = (data) => {
        sessionStorage.setItem(TOKEN, data.token)
        sessionStorage.setItem(USER_ID, data.userId)
        sessionStorage.setItem(CLIENT_ID, data.clientId)
        sessionStorage.setItem(DEF_PASS, data.isDefPass)
        isAuth.value    = true
        userId.value    = data.userId
        clientId.value  = data.clientId
        isDefPass.value = data.isDefPass
    }

    const authLogout = () => {
        sessionStorage.removeItem(TOKEN)
        sessionStorage.removeItem(USER_ID)
        sessionStorage.removeItem(CLIENT_ID)
        sessionStorage.removeItem(DEF_PASS)
        sessionStorage.removeItem(ORIGINAL_RATE)
        isAuth.value    = false
        userId.value    = null
        clientId.value  = null
        isDefPass.value = false
    }

    return (
        <AuthContext.Provider value={{ isAuth, userId, clientId, isDefPass, authLogin, authLogout }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)
