import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { signal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import { usePassword } from '../../hooks/usePassword'

const email = signal('')
const user = signal('')

const ForgotPassModal = ({ onClose, isOpen }) => {
    useSignals()

    const { isLoading, forgotPassword } = usePassword()

    function handleClose() {
        email.value = ''
        user.value = ''
        onClose()
    }

    function handleInputChange(e) {
        const { name, value } = e.target
        if (name === 'username') user.value = value
        if (name === 'email') email.value = value
    }

    async function handleSubmit() {
        const success = await forgotPassword(user.value.trim(), email.value.trim())
        if (success) handleClose()
    }

    return (
        <Modal isCentered isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Restaurar Contraseña</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={4}>
                        <FormLabel>Ingresa tu nombre de usuario</FormLabel>
                        <Input type='text' value={user.value} placeholder='Nombre de usuario' name='username' onChange={handleInputChange} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Ingresa tu email:</FormLabel>
                        <Input value={email.value} type='email' placeholder='ejemplo@dominio.com' name='email' onChange={handleInputChange} />
                    </FormControl>
                </ModalBody>
                <ModalFooter gap={4}>
                    <Button colorScheme='yellow' minW='100px' isLoading={isLoading.value} onClick={handleClose}>Cancelar</Button>
                    <Button colorScheme='blue' minW='100px' isLoading={isLoading.value} variant='outline' onClick={handleSubmit}>Enviar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ForgotPassModal