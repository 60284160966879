import { Box } from '@chakra-ui/react'
import BoxTitle from '../components/BoxTitle'
import ClientInfo from '../components/ClientInfo'
import { signal, useSignal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import ClientsTable from '../components/ClientsTable'
import useClients from '../hooks/useClients'


const triggerNewClient = signal(false)

const ClientsPage = () => {
    useSignals()
    const { client } = useClients()
    const typeFilter = useSignal('')
    const nameFilter = useSignal('')

    return (
        <Box className='box' maxW='8xl' minH='250px'>
            <BoxTitle text='Clientes' />
            <Box>
                <ClientInfo
                    nameFilter={nameFilter}
                    typeFilter={typeFilter}
                    onModalOpen={() => { client.value = null; triggerNewClient.value = true }}
                />
            </Box>
            <ClientsTable nameFilter={nameFilter} typeFilter={typeFilter} openNewClientModal={triggerNewClient} />
        </Box>
    )
}

export default ClientsPage
