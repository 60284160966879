import { Box, Button, HStack, Text } from '@chakra-ui/react'
import FooterModal from './modals/FooterModal'
import { signal } from '@preact/signals-react'
import { useAuth } from '../contexts/AuthContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSignals } from '@preact/signals-react/runtime'
import { CHANGE_PASS_ROUTE, CLIENT_SITE_ROUTE, GUIDE_ROUTE, PRIVACY_USECASE, SIDEBAR_ML } from '../utils/globalVars'

const useCase   = signal(null)
const showModal = signal(false)

const PageFooter = () => {
    useSignals()

    const navigate   = useNavigate()
    const location   = useLocation()
    const { isAuth } = useAuth()

    function handleModalOpen(input) {
        useCase.value = input
        showModal.value = true
    }

    return (
        <Box
            as='footer'
            className='footer'
            pl={{ base: '1rem',
                  lg: isAuth.value 
                      && location.pathname !== GUIDE_ROUTE 
                      && location.pathname !== CLIENT_SITE_ROUTE 
                      && location.pathname !== CHANGE_PASS_ROUTE 
                      ? SIDEBAR_ML : '1rem'
            }}
        >
            <FooterModal isActive={showModal.value} handleClose={() => showModal.value = false} useCase={useCase.value} />
            <Text textAlign='center' mb={3}>2024 Armando Merino℠</Text>
            <HStack flexDir={{ base: 'column', md: 'row' }} justifyContent='center' gap={{ base: 2, md: 10 }}>
                <Button variant='link' onClick={() => handleModalOpen(PRIVACY_USECASE)}>Privacidad</Button>
                <Button variant='link' onClick={() => handleModalOpen()}>Términos y Condiciones</Button>
                <Button variant='link' onClick={() => navigate(GUIDE_ROUTE)}>Guía para instrucciones</Button>
            </HStack>
        </Box>
    )
}

export default PageFooter