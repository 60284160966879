import { Heading } from '@chakra-ui/react'

const BoxTitle = ({text}) => {
    return (
        <Heading as='h2' size='md' fontWeight='bold' textAlign='center' mb={4} borderBottom='2px solid' borderColor='blue.300' pb={2} w='100%'>
            {text}
        </Heading>
    )
}

export default BoxTitle