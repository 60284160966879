import { batch, signal } from '@preact/signals-react'
import { memo, useRef } from 'react'
import ClientTypeSelect from '../ClientTypeSelect'
import useClients from '../../hooks/useClients'
import {
    Button,
    Divider,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack
} from '@chakra-ui/react'
import { CONTACT, EDIT_CLIENT_MODAL, EMAIL, FILES, NAME, NEW_CLIENT_MODAL, PHONE } from '../../utils/globalVars'

const emptyClient = { id: null, username: '', type: '', name: '', contact: '', phone: '', email: '', files: '' }

const hasUser = signal(false)
const formData = signal(emptyClient)
const isInit = signal(false)

function ClientModal({ client, onClose, isOpen }) {
    const initialRef = useRef(null)
    const { saveClient, updateClient, isLoading } = useClients()

    const initialize = () => {
        if (!isOpen) return
        isInit.value = true
        const clientData = client.value ? { ...client.value } : emptyClient

        clientData.username = clientData.username ?? ''
        clientData.contact = clientData.contact ?? ''
        clientData.files = clientData.files ?? ''

        batch(() => {
            formData.value = clientData
            hasUser.value  = !!clientData.username
        })
    }

    if (isOpen && !isInit.value) {
        initialize()
    }

    function handleChange(e) {
        const { name, value } = e.target
        formData.value = { ...formData.value, [name]: value}
    }

    async function handleSubmit() {
        if (formData.value.id === null) {
            const newId = await saveClient(formData.value)
            if (newId > 0) {
                client.value = { id: newId, name: 'new' }
                handleClose()
            }
        } else {
            const isChanged = await updateClient(formData.value.id, formData.value)
            if (isChanged) {
                client.value = { ...client.value, 'changed': true }
                handleClose()
            }
        }
    }

    function handleClose() {
        isInit.value = false
        hasUser.value = false
        onClose()
    }

    return (
        <Modal isOpen={isOpen} onClose={handleClose} initialFocusRef={initialRef}>
            <ModalOverlay />
            <ModalContent minW='xs'>
                <ModalHeader>{client.value ? EDIT_CLIENT_MODAL : NEW_CLIENT_MODAL}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack gap={4}>
                        {hasUser.value &&
                            <>
                                <FormControl>
                                    <FormLabel>Usuario:</FormLabel>
                                    <Input type='text' value={formData.value.username} onChange={handleChange} name='username' placeholder='Nombre de usuario' />
                                </FormControl>
                                <Divider h='1px' bg='blue.500' mt={4} />
                            </>
                        }
                        <FormControl>
                            <FormLabel>Tipo:</FormLabel>
                            <ClientTypeSelect value={formData.value.type} onChange={handleChange} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Nombre:</FormLabel>
                            <Input ref={initialRef} type='text' value={formData.value.name} onChange={handleChange} name={NAME} placeholder='Nombre del cliente' />
                        </FormControl>
                        {formData.value.type === 'Empresa' && (
                            <FormControl>
                                <FormLabel>Contacto:</FormLabel>
                                <Input type='text' value={formData.value.contact} onChange={handleChange} name={CONTACT} placeholder='Nombre del contacto' />
                            </FormControl>
                        )}
                        <FormControl>
                            <FormLabel>Teléfono:</FormLabel>
                            <Input type='number' value={formData.value.phone} onChange={handleChange} name={PHONE} pattern='[0-9]{10}' placeholder='1234567890' />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Email:</FormLabel>
                            <Input type='email' value={formData.value.email} onChange={handleChange} name={EMAIL} placeholder='ejemplo@dominio.com' />
                        </FormControl>
                        <Divider h='1px' bg='blue.500' mt={4} />
                        <FormControl>
                            <FormLabel>Archivos:</FormLabel>
                            <Input type='text' value={formData.value.files} onChange={handleChange} name={FILES} placeholder='Enlace de Google Drive' />
                        </FormControl>
                    </VStack>
                </ModalBody>
                <ModalFooter gap={4}>
                    <Button colorScheme='yellow' onClick={handleClose} isDisabled={isLoading.value}>Cancelar</Button>
                    <Button colorScheme='blue' variant='outline' isLoading={isLoading.value} onClick={handleSubmit}>Guardar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default memo(ClientModal)
