import { Box, FormControl, HStack, IconButton, Input, Tbody, Td, Th, Tr } from '@chakra-ui/react'
import { useRef } from 'react'
import { DeleteIcon } from '@chakra-ui/icons'
import { formatMoney } from '../utils/globalFunctions'
import { useSignal } from '@preact/signals-react'
import { FaPlay, FaPause } from 'react-icons/fa6'
import { useSignals } from '@preact/signals-react/runtime'

const InvoiceRows = ({rows, disableInput, onChange, onDeleteRow}) => {
    useSignals()
    const time         = useSignal(0)
    const isRunning    = useSignal(false)
    const intervalRef  = useRef(null)
    const clickedIndex = useSignal(null)

    function handleTimer (rowIndex, rowValue) {
        clickedIndex.value = rowIndex
        const val = Number(rowValue) || 0
        
        if(isRunning.value) {
            clearInterval(intervalRef.current)
            const timeInMin = time.value < 60 ? 1 : Math.round(time.value / 60)
            onChange(rowIndex, 'time', val + timeInMin)
            time.value = 0
        } else {
            intervalRef.current = setInterval(() => {
                time.value = time.value + 1
            }, 1000)
        }
        isRunning.value = !isRunning.value
    }
    
    return (
        <Tbody>
            {rows.value && rows.value.map((row, idx) => (                    
                <Tr key={idx}>
                    <Th scope='row' className='hidden' bg='blue.900'></Th>
                    <Td data-header='Acción'>
                        <HStack spacing={2}>
                            <IconButton 
                                isRound 
                                icon={<DeleteIcon/>} 
                                size='sm' 
                                colorScheme='red' 
                                maxW='fit-content' 
                                onClick={() => onDeleteRow(idx)} 
                                isDisabled={disableInput}
                            />
                        </HStack>
                    </Td>
                    <Td data-header='Descripción'>
                        <FormControl>
                            <Input
                                type='text'
                                placeholder='Descripción'
                                value={row.description}
                                isDisabled={disableInput}
                                onChange={(e) => onChange(idx,'description',e.target.value)}
                            />
                        </FormControl>
                    </Td>
                    <Td data-header='Tiempo'>
                        <FormControl display='flex' alignItems='center' gap={4} flexWrap='nowrap'>
                            <Input
                                type='number'
                                textAlign='right'
                                placeholder='Tiempo'
                                value={row.time}
                                isDisabled={disableInput}
                                onChange={(e) => onChange(idx,'time',e.target.value)}
                            />
                            <IconButton 
                                size='sm' 
                                isRound 
                                colorScheme={(isRunning.value && clickedIndex.value === idx) ? 'red' : 'green'} 
                                onClick={() => handleTimer(idx, row.time)} 
                                icon={(isRunning.value && clickedIndex.value === idx) ? <FaPause/> : <FaPlay/> } 
                            />
                        </FormControl>
                    </Td>
                    <Td data-header='Subtotal'>
                        <Box textAlign='right'>
                            {formatMoney(parseFloat(row.amount))}
                        </Box>
                    </Td>
                </Tr>
            ))}
        </Tbody>
    )
}

export default InvoiceRows