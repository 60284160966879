import { useCallback, useEffect } from 'react'
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
} from '@chakra-ui/react';
import { signal, useSignal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime';
import useClients from '../../hooks/useClients';
import ClientTypeSelect from '../ClientTypeSelect';
import { useNavigate } from 'react-router-dom';
import { CHANGE_PASS_ROUTE } from '../../utils/globalVars';

const originalData = signal({})

const PersonalDataModal = ({ client, onClose, isOpen }) => {
    useSignals()
    const navigate = useNavigate()
    const formData = useSignal({ id: null, username: '', type: '', name: '', contact: '', phone: '', email: '' })
    const { isLoading, dataChangeRequest, verifyClientData } = useClients()

    useEffect(() => {
        if (isOpen && client.value) {
            console.log('yes')
            if (client.value.contact === null) {
                client.value.contact = ''
            }
            originalData.value = structuredClone(client.value)
            formData.value = structuredClone(client.value)
        } else {
            originalData.value = { id: null, username: '', type: '', name: '', contact: '', phone: '', email: '' }
        }
    }, [client, isOpen, formData])

    const handleChange = useCallback((e) => {
        const { name, value } = e.target

        if (name === 'phone' && (isNaN(value) || value.length > 20)) {
            return
        }

        if (name === 'username' && value.includes(' ')) {
            return
        }
        formData.value = { ...formData.value, [name]: value }

        if (name === 'type' && value === 'Particular') {
            formData.value.contact = ''
        }
    }, [formData])

    async function handleSubmit() {
        formData.value.id = originalData.value.id
        const success = verifyClientData(formData.value, false)
        if (success) {
            const res = await dataChangeRequest(formData.value, originalData.value)
            if (res) {
                client.value.username = formData.value.username
                onClose()
            }
        } else {
            onClose()
        }
    }

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose} size='xl'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Solicitud de cambio de datos</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                        <Text textAlign='justify'>A continuación puedes solicitar cambios en tus datos actuales, los cuales se encuentran entre los paréntesis.</Text>
                        <FormControl mt={4}>
                            <FormLabel>Usuario: ({originalData.value.username})</FormLabel>
                            <Input type="text" value={formData.value.username} name='username' onChange={handleChange} />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Tipo: ({originalData.value.type})</FormLabel>
                            <ClientTypeSelect value={formData.value.type} onChange={handleChange} name='type' pHolder='Selecciona una opción...' />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Nombre: ({originalData.value.name})</FormLabel>
                            <Input border='1px solid #e2e8f0' type="text" value={formData.value.name} name='name' onChange={handleChange} />
                        </FormControl>
                        {formData.value.type === 'Empresa' &&
                            <FormControl mt={4}>
                                <FormLabel>Contacto: ({originalData.value.contact !== null ? originalData.value.contact : ''})</FormLabel>
                                <Input border='1px solid #e2e8f0' type="text" value={formData.value.contact} name='contact' onChange={handleChange} />
                            </FormControl>
                        }
                        <FormControl mt={4}>
                            <FormLabel>Teléfono: ({originalData.value.phone})</FormLabel>
                            <Input border='1px solid #e2e8f0' type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name='phone' value={formData.value.phone} onChange={handleChange} />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Email: ({originalData.value.email})</FormLabel>
                            <Input border='1px solid #e2e8f0' type="email" value={formData.value.email} name='email' onChange={handleChange} />
                        </FormControl>
                    </Box>
                </ModalBody>
                <ModalFooter gap={4}>
                    <Button mr='auto' colorScheme='blue' whiteSpace='normal' onClick={() => navigate(CHANGE_PASS_ROUTE)}>Cambiar contraseña</Button>
                    <Button colorScheme='yellow' isLoading={isLoading.value} onClick={onClose}>Cancelar</Button>
                    <Button colorScheme='blue' isLoading={isLoading.value} variant='outline' onClick={handleSubmit}>Solicitar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default PersonalDataModal