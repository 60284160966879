import { Button, ListItem, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Text, UnorderedList } from '@chakra-ui/react'

const PrivacyModalContent = ({ handleClose }) => {
    return (
        <ModalContent>
            <ModalHeader>Aviso de Privacidad</ModalHeader>
            <Text ml={6} mb={4} fontSize={12}><i>Vigente a partir del 7 de septiembre de 2024.</i></Text>
            <ModalCloseButton/>
            <ModalBody className='legal-info'>
                <h2>Responsable del Tratamiento de sus Datos Personales.</h2>
                <p>
                    Armando Merino López, con domicilio en Gonzaga 4386, Cd de los Niños, 45040 Zapopan, Jal. y correo electrónico <u>am@armandomerino.mx</u>, soy el responsable del tratamiento de los datos personales que me proporcionas.
                </p>
                <br/>
                <h2>Datos Personales que Recopilo.</h2>
                <p>Únicamente recopilo los siguientes datos personales:</p>
                <UnorderedList>
                    <ListItem>Nombre de la empresa (solo en caso de que representes a una).</ListItem>
                    <ListItem>Nombre.</ListItem>
                    <ListItem>Teléfono.</ListItem>
                    <ListItem>Correo electrónico.</ListItem>
                </UnorderedList>
                <br/>
                <h2>Métodos de Recopilación de Datos.</h2>
                <p>Recopilo la información personal cuando:</p>
                <UnorderedList>
                    <ListItem>Me contactas en el mundo real.</ListItem>
                    <ListItem>Me proporcionas información a través de redes sociales o servicios de mensajería.</ListItem>
                </UnorderedList>
                <br/>
                <p>Utilizo esta información para crearte un usuario en mi sitio, permitiéndote visualizar las facturas proforma correspondientes a los servicios contratados.</p>
                <br/>
                <h2>Finalidad del Tratamiento de Datos Personales.</h2>
                <p>Los datos personales que recopilo tienen las siguientes finalidades:</p>
                <UnorderedList>
                    <ListItem>Generar facturas proforma en formato PDF que contengan tu información.</ListItem>
                    <ListItem>Mantener un registro personal de mis clientes.</ListItem>
                    <ListItem>Enviar correos electrónicos únicamente con fines comunicativos.</ListItem>
                </UnorderedList>
                <br/>
                <h2>Compartición de Datos con Terceros.</h2>
                <p>Los datos personales recopilados no son compartidos con terceros. Son utilizados exclusivamente por mí para las finalidades mencionadas.</p>
                <br/>
                <h2>Derechos de los Usuarios (tus derechos).</h2>
                <p>Tú puedes acceder, rectificar o solicitar la eliminación de tus datos personales iniciando sesión en mi sitio y utilizando alguno de los formularios disponibles, o contactándome directamente a través de <u>am@armandomerino.mx</u>. En caso de solicitar la eliminación de tus datos, toma en cuenta que éstos serán marcados como "borrados" en mi base de datos y se anonimizarán tus datos personales, de modo que no puedan identificar a ninguna persona.</p>
                <p>Asimismo, toma en cuenta que únicamente podrás solicitar mis servicios aceptando mis Términos y Condiciones, así como el presente Aviso de Privacidad.</p>
                <br/>
                <h2>Medidas de Seguridad.</h2>
                <p>Para proteger tus datos personales, he implementado medidas de seguridad adecuadas, incluyendo la encriptación de contraseñas y la protección de mi base de datos y sitio web.</p>
                <br/>
                <h2>Retención de Datos</h2>
                <p>Mantendré tus datos personales en mi base de datos indefinidamente, o hasta que solicites que sean borrados. También podrán ser eliminados dada alguna necesidad emergente, situación de la cual serás notificado con al menos 2 semanas de antelación al borrado de tus datos.</p>
                <br/>
                <h2>Actualizaciones al Aviso de Privacidad.</h2>
                <p>Cualquier cambio en este aviso de privacidad te será notificado por correo electrónico antes de que los cambios entren en vigor.</p>
                <br/>
                <h2>Cumplimiento con Leyes y Regulaciones.</h2>
                <p>Este aviso de privacidad cumple con las leyes de privacidad aplicables en México y Jalisco.</p>
                <br/>
                <h2>Contacto.</h2>
                <p>Para cualquier pregunta o consulta sobre este aviso de privacidad, puedes contactarme en <u>am@armandomerino.mx</u>.</p>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='blue' onClick={handleClose}>Entendido</Button>
            </ModalFooter>
        </ModalContent>
    )
}

export default PrivacyModalContent