import { useRef } from 'react'
import { Box, Button, ButtonGroup, Flex, FormLabel, Input, Text, VStack } from '@chakra-ui/react'
import BoxTitle from '../components/BoxTitle'
import { formatMoney, onlyNumbersInput } from '../utils/globalFunctions'
import { useSignals } from '@preact/signals-react/runtime'
import { useRates } from '../hooks/useRates'

const ConfigPage = () => {
    useSignals()

    const inputRef = useRef(null)
    const { isLoading, month, hour, minute, saveRates, resetRates } = useRates()

    function handleChange(e) {
        month.value = e.target.value
    }

    function handleReset() {
        resetRates()
        inputRef.current.focus()
    }

    async function handleSave() {
        await saveRates()
        inputRef.current.focus()
    }

    return (
        <Box className='box' maxW='xs'>
            <BoxTitle text='Configuración' />
            <VStack w='100%'>
                <Input
                    type='number'
                    placeholder='Tarifa mensual' 
                    autoFocus 
                    ref={inputRef} 
                    value={month.value} 
                    onChange={handleChange} 
                    onKeyDown={onlyNumbersInput} 
                    isDisabled={isLoading.value}
                />
                <Flex pl={4} w='100%' justifyContent='flex-start'>
                    <FormLabel>Tarifa por hora:</FormLabel>
                    <Text>{formatMoney(hour.value)}</Text>
                </Flex>
                <Flex pl={4} w='100%' justifyContent='flex-start'>
                    <FormLabel>Tarifa por minuto:</FormLabel>
                    <Text>{formatMoney(minute.value)}</Text>
                </Flex>
                <ButtonGroup>
                    <Button minW='100px' colorScheme='yellow' onClick={handleReset} isDisabled={isLoading.value}>Resetear</Button>
                    <Button minW='100px' colorScheme='blue'   onClick={handleSave}  isDisabled={isLoading.value} variant='outline'>Guardar</Button>
                </ButtonGroup>
            </VStack>
        </Box>
    )
}

export default ConfigPage
