import { FormLabel, HStack, Button, Box, Input, useBreakpointValue } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { FaFilterCircleXmark } from 'react-icons/fa6'
import { useSignal } from '@preact/signals-react'

const ClientInfo = ({ nameFilter, typeFilter, onModalOpen }) => {
    const isWide = useBreakpointValue({base: false, md: true})

    const nameValue = useSignal('')

    function handleClear() {
        nameValue.value = ''
        nameFilter.value = ''
        typeFilter.value = ''
    }

    const handleInputChange = (e) => {
        nameValue.value = e.target.value
        nameFilter.value = e.target.value.toLowerCase()
    }

    return (
        <Box justify='space-between' wrap='wrap' mb={4}>
            <HStack>
                <FormLabel mb={0} fontWeight='bold'>Nombre:</FormLabel>
                <Input type='text' autoFocus value={nameValue.value} onChange={handleInputChange} placeholder='Buscar cliente...' />
                <Button colorScheme='yellow' variant='outline' w='fit-content' minW={{ base: 0, md: '100px' }} onClick={handleClear}>
                    {(isWide && 'Limpiar') || <FaFilterCircleXmark size={40} />}
                </Button>
                <Button
                    colorScheme='blue'
                    w='fit-content'
                    minW={{ base: 0, md: '100px' }}
                    onClick={onModalOpen}
                >
                    {(isWide && 'Nuevo') || <AddIcon />}
                </Button>
            </HStack>
        </Box>
    )
}

export default ClientInfo
