import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { Box, List, ListIcon, ListItem } from '@chakra-ui/react'
import { signal, useSignalEffect } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime';

export const criteria = {
    minLength: signal(false),
    uppercase: signal(false),
    lowercase: signal(false),
    number: signal(false),
    specialChar: signal(false)
}

const PasswordChecker = ({password}) => {
    useSignals()

    const updateCriteria = (password) => {
        criteria.minLength.value = password.length >= 8
        criteria.uppercase.value = /[A-Z]/.test(password)
        criteria.lowercase.value = /[a-z]/.test(password)
        criteria.number.value = /[0-9]/.test(password)
        criteria.specialChar.value = /[^A-Za-z0-9]/.test(password)
    };


    useSignalEffect(() => {
        updateCriteria(password.value);
    });

    return (
        <Box>
            <List spacing={2} fontSize={13}>
                <ListItem>
                    <ListIcon as={criteria.minLength.value ? CheckCircleIcon : WarningIcon} color={criteria.minLength.value ? 'green.500' : 'red.500'} />
                    Mínimo 8 caracteres
                </ListItem>
                <ListItem>
                    <ListIcon as={criteria.uppercase.value ? CheckCircleIcon : WarningIcon} color={criteria.uppercase.value ? 'green.500' : 'red.500'} />
                    Al menos una letra mayúscula
                </ListItem>
                <ListItem>
                    <ListIcon as={criteria.lowercase.value ? CheckCircleIcon : WarningIcon} color={criteria.lowercase.value ? 'green.500' : 'red.500'} />
                    Al menos una letra minúscula
                </ListItem>
                <ListItem>
                    <ListIcon as={criteria.number.value ? CheckCircleIcon : WarningIcon} color={criteria.number.value ? 'green.500' : 'red.500'} />
                    Al menos un número
                </ListItem>
                <ListItem>
                    <ListIcon as={criteria.specialChar.value ? CheckCircleIcon : WarningIcon} color={criteria.specialChar.value ? 'green.500' : 'red.500'} />
                    Al menos un carácter especial
                </ListItem>
            </List>
        </Box>
    );
};
    

export default PasswordChecker;
