import { useEffect, useRef } from 'react'
import { Box, Button, Flex, HStack, Text, useDisclosure, useToast, VStack } from '@chakra-ui/react'
import { formatMoney, showToast } from '../utils/globalFunctions'
import { INFO, LABEL_WIDTH, NO_FILES_FOUND, TITLE_INFO } from '../utils/globalVars'
import { signal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import { useAuth } from '../contexts/AuthContext'
import useClients from '../hooks/useClients'
import useInvoices from '../hooks/useInvoices'
import BoxTitle from '../components/BoxTitle'
import InvoicesTable from '../components/InvoicesTable'
import InvoiceModal from '../components/modals/InvoiceModal'
import PaymentModal from '../components/modals/PaymentModal'
import ContactModal from '../components/modals/ContactModal'
import PersonalDataModal from '../components/modals/PersonalDataModal'

const totalInvoiced   = signal(0)
const totalDebt       = signal(0)
const title           = signal(' ')
const selectedInvoice = signal(null)
const paymentObject   = signal(null)

const ClientSitePage = () => {
    useSignals()

    const toast = useToast()
    const { authLogout } = useAuth()
    const { isLoading, client, getSingleClient } = useClients()
    const { invoiceList, getInvoices, reportPayment } = useInvoices()

    //Modal Controllers
    const { isOpen: isInvoiceOpen,                        onClose: onInvoiceClose } = useDisclosure()
    const { isOpen: isDataOpen,    onOpen: onDataOpen,    onClose: onDataClose    } = useDisclosure()
    const { isOpen: isContactOpen, onOpen: onContactOpen, onClose: onContactClose } = useDisclosure()
    const { isOpen: isReportOpen,  onOpen: onReportOpen,  onClose: onReportClose  } = useDisclosure()

    

    async function fetchData() {
        await getSingleClient()
        title.value = `Hola, ${client.value?.name || ''}`
        const success = await getInvoices(client.value.id)
        clientRef.value = client.value
        if (success) {
            const [invoiced, debt] = invoiceList.value.reduce(
                ([invoicedSum, debtSum], invoice) => [invoicedSum + invoice.total, debtSum + invoice.debt],
                [0, 0]
            )
            totalInvoiced.value = invoiced
            totalDebt.value = debt
        }
    }

    const fetchRef = useRef(fetchData)
    const clientRef = useRef(null)

    useEffect(() => {
        if (!clientRef.current) {
            fetchRef.current()
        }
    }, [])

    function handleFilesClick() {
        if (client.value?.files?.length) {
            window.open(client.value.files, '_blank', 'noopener,noreferrer')
        } else {
            showToast(toast, TITLE_INFO, NO_FILES_FOUND, INFO)
        }
    }


    async function handlePaymentClose() {
        onReportClose()
        const {value: payment } = paymentObject
        if (payment) {
            const amountPay = payment.pay === -1 ? selectedInvoice.value.total : payment.pay
            if (amountPay > 0) {
                await reportPayment(selectedInvoice.value.id, amountPay)
            }
            paymentObject.value = null
        }
    }

    function handlePaymentReport(invoice) {
        selectedInvoice.value = invoice
        onReportOpen()
    }
    
    return (
        <Box className='box' maxW='8xl' minH='250px'>
            <InvoiceModal
                invoice={selectedInvoice.value}
                onClose={onInvoiceClose}
                isOpen={isInvoiceOpen}
                status={selectedInvoice.value && selectedInvoice.value.status}
            />
            <PersonalDataModal client={client} onClose={onDataClose} isOpen={isDataOpen} />
            <ContactModal client={client} invoiceList={invoiceList} onClose={onContactClose} isOpen={isContactOpen} />
            <PaymentModal isOpen={isReportOpen} onClose={handlePaymentClose} isConfirmed={paymentObject} isClient />
            <BoxTitle text={title.value} />
            <Box className='client-box' flexDir={{ base: 'column', lg: 'row' }}>
                <Flex className='client-data-wrap' w={{ base: '100%', lg: '50%' }} bgColor='whiteAlpha.300'>
                    <Flex className='client-data' gap={{ base: 1, sm: 4 }} flexDir={{ base: 'column', sm: 'row' }}>
                        <Text as='h2' fontWeight='bold' textAlign='center' fontSize='14pt'>Tus datos personales</Text>
                        <Button minW='fit-content' size='xs' variant='outline' borderRadius={4} onClick={onDataOpen} color='gray.600' isDisabled={isLoading.value}>Solicitar cambios</Button>
                    </Flex>
                    <VStack alignItems='flex-start' ml='auto' mr='auto'>
                        <HStack alignItems='baseline'>
                            <Text fontWeight='bold' minW={LABEL_WIDTH} w={LABEL_WIDTH} m={0} textAlign='right'>Tipo de cliente:</Text>
                            <Text ml={2}>{client.value && client.value.type}</Text>
                        </HStack>
                        <HStack alignItems='baseline'>
                            <Text fontWeight='bold' minW={LABEL_WIDTH} w={LABEL_WIDTH} m={0} textAlign='right'>Nombre:</Text>
                            <Flex wrap='wrap' maxW='100%' w='100%'>
                                <Text ml={2} overflowWrap='break-word' wordBreak='break-word'>{client.value && client.value.name}</Text>
                            </Flex>
                        </HStack>
                        <HStack alignItems='baseline'>
                            <Text fontWeight='bold' minW={LABEL_WIDTH} w={LABEL_WIDTH} m={0} textAlign='right'>Teléfono:</Text>
                            <Text ml={2}>{client.value && client.value.phone}</Text>
                        </HStack>
                        <HStack alignItems='baseline'>
                            <Text fontWeight='bold' minW={LABEL_WIDTH} w={LABEL_WIDTH} m={0} textAlign='right'>Email:</Text>
                            <Flex wrap='wrap' maxW='100%' w='100%'>
                                <Text ml={2} overflowWrap='break-word' wordBreak='break-word'>{client.value && client.value.email}</Text>
                            </Flex>
                        </HStack>
                        {client.value && client.value.type === 'Empresa' &&
                            <HStack alignItems='baseline'>
                                <Text fontWeight='bold' minW={LABEL_WIDTH} w={LABEL_WIDTH} m={0} textAlign='right'>Contacto:</Text>
                                <Text ml={2}>{client.value.contact}</Text>
                            </HStack>}
                    </VStack>
                </Flex>
                <Flex className='client-data-wrap' w={{ base: '100%', lg: '50%' }} bgColor='whiteAlpha.300'>
                    <Flex className='client-data' gap={{ base: 1, sm: 4 }} flexDir={{ base: 'column', sm: 'row' }}>
                        <Text as='h2' fontWeight='bold' textAlign='center' fontSize='14pt'>Tus datos de facturación</Text>
                        <Button minW='fit-content' size='xs' color='gray.600' variant='outline' isDisabled={isLoading.value} borderRadius={4} onClick={onContactOpen}>Solicitar aclaración</Button>
                    </Flex>
                    <VStack alignItems='flex-start' ml='auto' mr='auto' mb={4}>
                        <HStack alignItems='baseline'>
                            <Text fontWeight='bold' minW={LABEL_WIDTH} w={LABEL_WIDTH} m={0} textAlign='right'>Total Facturado:</Text>
                            <Text ml={2}>{formatMoney(totalInvoiced.value)}</Text>
                        </HStack>
                        <HStack alignItems='baseline'>
                            <Text fontWeight='bold' minW={LABEL_WIDTH} w={LABEL_WIDTH} m={0} textAlign='right'>Adeudo Total:</Text>
                            <Text ml={2}>{formatMoney(totalDebt.value)}</Text>
                        </HStack>
                    </VStack>
                </Flex>
                <Flex className='client-btns' w={{ base: '100%', lg: 'fit-content' }} flexDir={{ base: 'row', lg: 'column' }} justifyContent={{ base: 'center', lg: 'flex-start' }} alignItems={{ base: 'center', lg: 'flex-end' }}>
                    <Button colorScheme='red' isDisabled={isLoading.value} maxW='fit-content' onClick={authLogout} minW='129px'>Cerrar Sesión</Button>
                    <Button colorScheme='blue' isDisabled={isLoading.value} onClick={handleFilesClick} minW='129px'>Mis Archivos</Button>
                </Flex>
            </Box>
            <BoxTitle text='Facturas' />
            <InvoicesTable onReport={handlePaymentReport} />
        </Box>
    )
}

export default ClientSitePage
