import { Box, Button, Container, Divider, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import BoxTitle from '../components/BoxTitle'
import { useNavigate } from 'react-router-dom'
import { LOGIN_ROUTE } from '../utils/globalVars'

const GuidePage = () => {
    const navigate = useNavigate()

    return (
        <Box className='box'>
            <BoxTitle text='Guia Práctica para Instrucciones de Edición de Audio.' />
            <Container maxW='95%' className='legal-info'>
                <Text>¡Gracias por confiar en mi trabajo de edición y mezcla de audio! Para asegurarme de que el resultado final sea exactamente lo que deseas, he creado esta guía para ayudarte a expresar tus ideas de forma clara y precisa.</Text>
                <br/>
                <h2>Describir la Edición de las Pistas de Audio.</h2>
                <UnorderedList>
                    <ListItem><strong>Corte de pistas:</strong> Indica los tiempos específicos (minuto y segundo) donde deseas que inicie y termine una sección de la canción que deseas incluir. Ejemplo: “Cortar de 00:45 a 01:30”.</ListItem>
                    <ListItem><strong>Repeticiones:</strong> Si quieres que una parte de la canción se repita, señala el intervalo de tiempo y cuántas veces repetirla. Ejemplo: “Repetir el coro que inicia en 01:20 y termina en 01:50 dos veces”.</ListItem>
                    <ListItem><strong>Cues musicales:</strong> Puedes usar referencias como “cuando suena el solo de guitarra” o “en la parte donde la letra dice ‘amor eterno’” para indicar los puntos específicos donde quieres que realice una edición. Esto es especialmente útil si no tienes los tiempos exactos o deseas que la edición se haga con mayor precisión.</ListItem>
                </UnorderedList>
                <br/>
                <h2>Mezcla de Pistas.</h2>
                <UnorderedList>
                    <ListItem><strong>Orden de las Pistas:</strong> Indica el orden en que deseas que las canciones o pistas se mezclen. Ejemplo: “Primero la pista 1, luego la pista 2”.</ListItem>
                    <ListItem><strong>Duración de las Transiciones:</strong> Si quieres que las pistas se mezclen de forma gradual, especifica cuánto tiempo deseas que dure la transición entre ellas. Ejemplo: “Una transición de 5 segundos entre la pista 1 y la pista 2”.</ListItem>
                </UnorderedList>
                <br/>
                <h2>Transiciones y Efectos Sonoros.</h2>
                <UnorderedList>
                    <ListItem><strong>Tipo de Efecto:</strong> Describe el tipo de efecto que quieres entre las pistas o en una parte específica de la canción. Ejemplo: “Agregar un efecto de fade out (desvanecimiento) al final de la pista 1”.</ListItem>
                    <ListItem><strong>Punto de Inserción del Efecto:</strong> Indica en qué momento exacto (minuto y segundo) quieres que se aplique el efecto. Ejemplo: “Agregar un eco en el último verso a partir de 02:15”.</ListItem>
                </UnorderedList>
                <br/>
                <h2>Notas Adicionales.</h2>
                <UnorderedList>
                    <ListItem><strong>Referencias:</strong> Si tienes ejemplos de otras canciones o pistas que tengan efectos o ediciones similares a lo que deseas, compártelos. Esto me ayudará a entender mejor lo que buscas.</ListItem>
                    <ListItem><strong>Especificaciones de Formato:</strong> Si tienes alguna preferencia en cuanto al formato de salida (mp3, wav, etc.), házmelo saber.</ListItem>
                </UnorderedList>
                <br/>
                <h2>Comunicación Continua.</h2>
                <UnorderedList>
                    <ListItem><strong>Revisión y Comentarios:</strong> Después de recibir la primera versión editada, puedes enviarme comentarios específicos si deseas ajustes adicionales.</ListItem>
                </UnorderedList>
            </Container>
            <Divider m={8} h='1px' bg='blue.500'  />
            <Container maxW='95%' className='legal-info'>
                <h1>Ejemplo de Solicitud Detallada.</h1>
                <Text>El siguiente es un ejemplo de cómo podrían verse tus instrucciones. Nota cómo las instrucciones van en secuencia de lo que quieres que incluya la pista editada; así mismo, toma en cuenta que los tiempos indicados siempre deberán ser los de la pista original; es decir, los tiempos de la pista sin editar.</Text>
                <br/>
                <h2>Pista 1: Canción A.</h2>
                <UnorderedList>
                    <ListItem>0:00 a 1:31</ListItem>
                    <ListItem>2:15 a 3:01, justo antes de que comience a cantar.</ListItem>
                    <ListItem>3:32 a final</ListItem>
                </UnorderedList>
                <br/>
                <h2>Pista 2: Canción B.</h2>
                <UnorderedList>
                    <ListItem>0:08 a 0:46, donde termina de sonar el piano.</ListItem>
                    <ListItem>1:07 a 1:42. Repetir este coro.</ListItem>
                    <ListItem>2:19 a 2:43. Aquí poner un efecto como “boom”, antes de pegar la parte siguiente.</ListItem>
                    <ListItem>3:11 a 4:01.</ListItem>
                    <ListItem>Pegar con el final de la cación.</ListItem>
                </UnorderedList>
                <br/>
                <h2>Pista 3: Canción C.</h2>
                <UnorderedList>
                    <ListItem>0:00 a 0:27</ListItem>
                    <ListItem>1:13 a 1:48. Agregar eco al final.</ListItem>
                    <ListItem>Dejar 10 segundos de silencio.</ListItem>
                    <ListItem>Poner un efecto como de audio en reversa que vaya incrementando de volumen y dure 4 segundos.</ListItem>
                    <ListItem>2:33 a 3:21</ListItem>
                    <ListItem>Poner un final con efecto de aplausos.</ListItem>
                </UnorderedList>
                <br/>
                <h2>Transición</h2>
                <UnorderedList>
                    <ListItem>Poner una transición entre la pista anterior y la siguiente que dure 5 segundos. ¿Podrías sugerir un tipo de efecto que quede bien?</ListItem>
                </UnorderedList>
                <br/>
                <h2>Pista 4: Canción D.</h2>
                <UnorderedList>
                    <ListItem>No importa dónde edites, solo necesito que dure 2:00.</ListItem>
                    <ListItem>Al final ponerle un efecto que tenga distorción y que termine de súbito.</ListItem>
                </UnorderedList>
                <br/>
                <h2>Pista 5: Canción E.</h2>
                <UnorderedList>
                    <ListItem>0:00 a 1:30 y pegar un final.</ListItem>
                    <ListItem>Subir el volumen de las partes que están muy bajito.</ListItem>
                </UnorderedList>
            </Container>
            <Divider m={8} h='1px' bg='blue.500' />
            <Flex>
                <Button colorScheme='blue' onClick={() => navigate(LOGIN_ROUTE)}>Volver</Button>
            </Flex>
        </Box>
    )
}

export default GuidePage