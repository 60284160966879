import { signal, useSignal } from '@preact/signals-react'
import { useCallback, useEffect, useRef } from 'react'
import {
    changeClientCall,
    contactRequestCall,
    createUserCall,
    deleteClientCall,
    getClientsCall,
    getSingleClientCall,
    getUsernamesCall,
    notifyChangeCall,
    notifyFilesCall,
    saveClientCall,
    updateClientCall
} from '../services/ApiCalls'
import { useToast } from "@chakra-ui/react"
import { showToast } from "../utils/globalFunctions"
import {
    CLIENT_ID,
    CLIENTS_ROUTE,
    DEFAULT_ERROR,
    ERROR,
    HISTORY_ROUTE,
    INFO,
    INVOICE_ROUTE,
    NO_CHANGES,
    NO_CLIENTS_FOUND,
    SUCCESS,
    TITLE_ERROR,
    TITLE_INFO,
    TITLE_SUCCESS,
    USERNAME_UPDATED
} from '../utils/globalVars'
import { useLocation } from 'react-router-dom'

const client      = signal({ id: null, name: '' })
const clientsList = signal([])

const useClients = () => {
    const toast     = useToast()
    const location  = useLocation()
    const isLoading = useSignal(false)
    const isInit    = useRef(false)
    const pathname  = useRef(location.pathname)

    const getClients = useCallback(async () => {
        isLoading.value = true
        try {
            const { status, data } = await getClientsCall()
            if (status === 200) {
                clientsList.value = data
            } else if (status === 204) {
                showToast(toast, TITLE_INFO, NO_CLIENTS_FOUND, INFO)
            } else {
                showToast(toast, TITLE_ERROR, data, ERROR)
            }
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data, ERROR)
        } finally {
            isLoading.value = false
        }
    }, [isLoading, toast])

    const getSingleClient = useCallback(async () => {
        isLoading.value = true
        try {
            const id = sessionStorage.getItem(CLIENT_ID)
            const { status, data } = await getSingleClientCall(id)
            if (status === 200) {
                client.value = data[0]
            } else {
                showToast(toast, TITLE_ERROR, data, ERROR)
            }
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
    }, [toast, isLoading])

    const updateClient = useCallback(async (clientId, clientData) => {
        isLoading.value = true
        try {
            const { status, data } = await updateClientCall(clientId, clientData)
            if (status === 200) {
                clientsList.value = clientsList.value.map(client => client.id === clientId ? { ...client, ...clientData } : client)
                showToast(toast, TITLE_SUCCESS, data, SUCCESS)
                return !data.includes(NO_CHANGES)
            }
            showToast(toast, TITLE_ERROR, data, ERROR)
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return false
    }, [isLoading, toast])

    const saveClient = useCallback(async (clientData) => {
        isLoading.value = true
        try {
            if (!verifyDataRef.current(clientData, true)) return 0

            const { status, data } = await saveClientCall(clientData)
            if (status === 201) {
                const clientId = data.split(': ')[1]
                clientData.id = clientId
                clientData.contact = clientData.contact || 'N/A';
                clientsList.value.push({ id: clientId })
                clientsList.value = clientsList.value.map(client => client.id === clientId ? { ...client, ...clientData } : client)
                showToast(toast, TITLE_SUCCESS, data, SUCCESS)
                return clientId
            }
            showToast(toast, TITLE_ERROR, data, ERROR)
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return 0
    }, [isLoading, toast])

    const dataChangeRequest = useCallback(async (newData, oldData) => {
        isLoading.value = true
        try {
            const [dataChanged, usernameChanged] = infoChanged(oldData, newData)

            if (!dataChanged && !usernameChanged) {
                showToast(toast, TITLE_INFO, NO_CHANGES, INFO)
                return true
            }

            await getUsernamesCall(newData.username, newData.id)

            const { status, data } = await changeClientCall(newData)
            if (status === 200) {
                let toastMessage = ''
                if (usernameChanged) {
                    toastMessage += USERNAME_UPDATED
                }
                if (dataChanged) {
                    toastMessage += data
                }
                showToast(toast, TITLE_SUCCESS, toastMessage, SUCCESS)
                return true
            }
            showToast(toast, TITLE_SUCCESS, data, SUCCESS)
            return true
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return false
    }, [toast, isLoading])

    function infoChanged(oldData, newData) {
        let dataChanged = false
        let userChanged = false

        for (const key in oldData) {
            if (key === 'username') {
                userChanged ||= oldData[key] !== newData[key]
            } else {
                dataChanged ||= oldData[key] !== newData[key]
            }

            if (dataChanged && userChanged) break
        }

        return [dataChanged, userChanged]
    }

    const contactRequest = useCallback(async (data) => {
        isLoading.value = true

        if (data.id === null || data.id === undefined || isNaN(data.id)) {
            showToast(toast, TITLE_ERROR, 'No se encontró tu ID de cliente', ERROR)
            return false
        }
        if (data.name === null || data.name === undefined || data.name.trim().length === 0) {
            showToast(toast, TITLE_ERROR, 'No se encontró tu nombre de cliente', ERROR)
            return false
        }
        if (data.topic === null || data.topic === undefined || data.topic.trim().length === 0) {
            showToast(toast, TITLE_ERROR, 'Por favor ingresa un tema', ERROR)
            return false
        }
        if (data.msg === null || data.msg === undefined || data.msg.trim().length === 0) {
            showToast(toast, TITLE_ERROR, 'Por favor ingresa un mensaje', ERROR)
            return false
        }

        try {
            const res = await contactRequestCall(data)
            if (res.status === 200) {
                showToast(toast, TITLE_SUCCESS, res.data, SUCCESS)
                return true
            }
            showToast(toast, TITLE_SUCCESS, res.data, SUCCESS)
            return true
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return false
    }, [toast, isLoading])

    const notifyChange = useCallback(async (clientId) => {
        isLoading.value = true
        try {
            const res = await notifyChangeCall(clientId)
            if (res.status === 200) {
                showToast(toast, TITLE_SUCCESS, res.data, SUCCESS)
                return
            }
            showToast(toast, TITLE_ERROR, res.data, ERROR)
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return
    }, [toast, isLoading])

    function verifyClientData(client, isNew) {
        if (client.id < 0 || isNaN(client.id)) {
            showToast(toast, 'ERROR', 'No se encontró tu ID', ERROR)
            return false
        }
        if (client.name.trim().length === 0) {
            showToast(toast, 'ERROR', 'Tu nombre no puede estar vacío', ERROR)
            return false
        }
        if (isNaN(client.phone) || client.phone.trim().length < 10) {
            showToast(toast, 'ERROR', 'Teléfono no válido', ERROR)
            return false
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(client.email)) {
            showToast(toast, TITLE_ERROR, 'Email no válido', ERROR)
            return false
        }
        if (client.type !== 'Empresa' && client.type !== 'Particular') {
            showToast(toast, TITLE_ERROR, 'Tipo de cliente no válido', ERROR)
            return false
        }

        if (client.type === 'Empresa' && (client.contact === null || client.contact.trim().length === 0)) {
            showToast(toast, TITLE_ERROR, 'Contacto no válido', ERROR)
            return false
        }
        if (!isNew && client.username.trim().length <= 0) {
            showToast(toast, 'ERROR', 'El nombre de usuario no puede estár vacío ni contener espacios en blanco', ERROR)
            return false
        }

        return true
    }

    const deleteClient = useCallback(async (clientId) => {
        isLoading.value = true
        try {
            const res = await deleteClientCall(clientId)
            if (res.status === 200) {
                clientsList.value = clientsList.value.filter(client => client.id !== clientId)
                showToast(toast, TITLE_SUCCESS, res.data, SUCCESS)
            }
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return
    }, [isLoading, toast])

    const createUser = useCallback(async (clientId) => {
        isLoading.value = true
        try {
            let userData = null
            const resClient = await getSingleClientCall(clientId)

            if (resClient.status === 200) {
                userData = {
                    client: resClient.data[0].type === 'Empresa' ? resClient.data[0].contact : resClient.data[0].name,
                    username: (resClient.data[0].name).toLowerCase().replace(/ /gi, '_'),
                    email: resClient.data[0].email,
                    clientId: clientId
                }
            }

            if (userData === null) {
                showToast(toast, TITLE_ERROR, 'Error al crear al usuario', ERROR)
                return
            }

            const { status, data } = await createUserCall(userData)
            if (status === 200) {
                const username = data.match(/Nombre de usuario: '([^']+)'/)[1]
                clientsList.value = clientsList.value.map((client) => client.id === clientId ? { ...client, username: username } : client)
                showToast(toast, TITLE_SUCCESS, data, SUCCESS)
                return
            }

            showToast(toast, TITLE_ERROR, data, ERROR)
        } catch (error) {
            let msgType = ERROR
            let title = TITLE_ERROR
            if (error.response.status === 409) {
                msgType = INFO
                title = TITLE_INFO
            }
            showToast(toast, title, error.response.data, msgType)
        } finally {
            isLoading.value = false
        }
        return
    }, [isLoading, toast])

    const notifyAddedFiles = useCallback(async (clientId) => {
        isLoading.value = true
        try {
            const res = await notifyFilesCall(clientId)
            if (res.status === 200) {
                showToast(toast, TITLE_SUCCESS, res.data, SUCCESS)
                return
            }
            showToast(toast, TITLE_ERROR, res.data, ERROR)
        } catch (error) {
            showToast(toast, TITLE_ERROR, error.response?.data || DEFAULT_ERROR, ERROR)
        } finally {
            isLoading.value = false
        }
        return
    }, [toast, isLoading])


    const fetchClientsRef = useRef(getClients)
    const verifyDataRef   = useRef(verifyClientData)

    useEffect(() => {
        if (!isInit.current
            && clientsList.value.length === 0
            && (pathname.current === HISTORY_ROUTE ||
                pathname.current === CLIENTS_ROUTE ||
                pathname.current === INVOICE_ROUTE
            )
        ) {
            fetchClientsRef.current()
            isInit.current = true
        }
    }, [])

    return {
        client,
        clientsList,
        updateClient,
        saveClient,
        deleteClient,
        createUser,
        getSingleClient,
        dataChangeRequest,
        verifyClientData,
        contactRequest,
        notifyChange,
        notifyAddedFiles,
        isLoading
    }
}

export default useClients
