export const API_URL     = process.env.REACT_APP_API_BASE_URL
export const API_METHODS = {get: 'GET', post: 'POST', put: 'PUT', del: 'DELETE'}
export const DRIVE_PATH = 'https://drive.google.com/drive/folders/1JwX4KaNjBgkgwJG_ye40MMcj2qxRpNS3?usp=sharing'

//LOCATIONS
export const GUIDE_ROUTE       = '/guide'
export const LOGIN_ROUTE       = '/login'
export const INVOICE_ROUTE     = '/invoice'
export const CLIENTS_ROUTE     = '/clients'
export const HISTORY_ROUTE     = '/history'
export const CONFIG_ROUTE      = '/config'
export const CLIENT_SITE_ROUTE = '/client-site'
export const CHANGE_PASS_ROUTE = '/change-pass'
export const RESET_PASS_ROUTE  = '/reset-pass'

// PROPERTIES
export const LABEL_WIDTH         = '125px'
export const SIDEBAR_ML          = '200px'
export const SIDEBAR_HIDE        = '-215px'
export const PRIVACY_USECASE     = 'privacy'
export const TOKEN               = 'token'
export const USER_ID             = 'userId'
export const CLIENT_ID           = 'clientId'
export const DEF_PASS            = 'isDefPass'
export const EMPRESA             = 'Empresa'
export const CHANGE_USECASE      = 'change'
export const RESTORE_USECASE     = 'restore'
export const ORIGINAL_RATE       = 'originalRate'


// TOAST CONSTANTS
export const TITLE_SUCCESS  = 'Éxito'
export const TITLE_ERROR    = 'Error'
export const TITLE_INFO     = 'Información'
export const SUCCESS        = 'success'
export const ERROR          = 'error'
export const INFO           = 'info'

// ALERT TITLES
export const TITLE_DEL_CLIENT = 'Eliminar Cliente'
export const TITLE_NOTIFY_CLIENT = 'Notificar Cliente'
export const TITLE_CREATE_USER = 'Crear usuario'


// TOAST MESSAGES
export const DEFAULT_ERROR       = 'Ocurrió un error.'
export const NO_INVOICES_FOUND   = 'No se encontraron facturas para el cliente especificado.'
export const NO_CLIENTS_FOUND    = 'No tienes clientes aún. Crea uno para empezar.'
export const NO_CHANGES          = 'No se realizaron cambios.'
export const USERNAME_UPDATED    = 'Tu nombre de usuario fue actualizado con éxito. '
export const CLIENT_NOT_FOUND    = 'Ocurrió un error y no fue posible realizar tu solicitud.'
export const RESET_PASS_ERROR    = 'Por favor ingresa tu nombre de usuario y tu correo electrónico.'
export const BLANK_SPACE_IN_PASS = 'La contraseña no puede tener espacios en blanco.'
export const NO_PASS_MATCH       = 'Las contraseñas no coinciden.'
export const CRITERIA_ERROR      = 'La contraseña no cumple con los criterios mínimos.'
export const TOKEN_NOT_FOUND     = 'No se encontró un Token.'
export const USER_ID_NOT_FOUND   = 'No se encontró un ID de usuario.'
export const NO_FILES_FOUND      = 'Aún no cuentas con archivos. Te notificaré cuando estén listos.'
export const NO_CHANGES_MSG      = 'No se realizaron cambios'
export const CONFIRM_INVOICE_DEL = '¿Seguro que deseas eliminar la factura con ID: __id__?'
export const CONFIRM_CLIENT_DEL  = '¿Seguro que deseas eliminar al cliente __name__ con ID: __id__?'
export const CREATE_USER_ASK     = '¿Deseas crear un usuario nuevo para este cliente?'
export const NOTIFY_CLIENT_ASK   = '¿Deseas notificar los cambios al cliente?'
export const NO_INVOICE_SELECTED = 'Por favor selecciona una factura.'
export const NO_CLIENT_SELECTED  = 'Por favor selecciona un cliente.'
export const NO_INVOICE_ID       = 'No se encontró un ID de factura.'
export const NO_ITEMS_FOUND      = 'Uno o más ítems de la factura no tienen descripción. Por favor, agrega una descripción para cada ítem.'
export const NO_TIME_FOUND       = 'Uno o más ítems de la factura no tienen tiempo especificado. Por favor, agrega el tiempo correspondiente para cada ítem.'
export const NO_AMOUNT_FOUND     = 'Uno o más ítems de la factura no tienen importe asignado. Por favor, asegúrate de agregar un importe para cada ítem.'



// INVOICE PROPERTIES
export const ID     = 'id'
export const TOTAL  = 'total'
export const DATE   = 'date'
export const STATUS = 'status'
export const DEBT   = 'debt'

// CLIENT PROPERTIES
export const TYPE = 'type'
export const NAME = 'name'
export const CONTACT = 'contact'
export const PHONE = 'phone'
export const EMAIL = 'email'
export const FILES = 'files'

// MODAL TITLES
export const EDIT_CLIENT_MODAL = 'Editar Cliente'
export const NEW_CLIENT_MODAL  = 'Nuevo Cliente'
