import { Box, Center, Flex, Button, Text, useDisclosure } from '@chakra-ui/react'
import LoginForm from '../components/LoginForm'
import BoxTitle from '../components/BoxTitle'
import ForgotPassModal from '../components/modals/ForgotPassModal'
import { useSignals } from '@preact/signals-react/runtime'

const LoginPage = () => {
    useSignals()
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Center flexDir='column'>
            <ForgotPassModal onClose={onClose} isOpen={isOpen} />
            <Box className='box' w='xs'>
                <BoxTitle text='Iniciar Sesión' />
                <LoginForm />
                <Flex mt={4} justifyContent='flex-end' w='100%'>
                    <Button size='xs' variant='link'>
                        <Text textDecor='underline' onClick={onOpen}>Olvidé mi contraseña</Text>
                    </Button>
                </Flex>
            </Box>
            <Center mt={4} w='xs' textAlign='center' textColor='gray.500' fontSize={10}>
                Al iniciar sesión, aceptas mis Términos y Condiciones, así como el Aviso de Privacidad. Puedes consultarlos en la parte inferior de la página.
            </Center>
        </Center>
    )
}

export default LoginPage
